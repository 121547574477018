import "./errorbox.scss"
import {AiFillWarning} from "react-icons/ai";

import React from 'react'



// Text is a <p> element.
// wrap the text with a span for bold.

export default function ErrorBox({text}) {
    return (
        <>
            <div className="ErrorBox-container"><AiFillWarning size={40}/> 
                {text}
                </div>
        </>
    )
}
