import './profilescreen.scss'
import {Link} from "react-router-dom";
import {useState, useEffect} from 'react';
import DeleteAccountModal from "./DeleteAccountModal";
import {userRequests} from "../../../../api/userRequests";
import {doAuthenticatedRequest} from "../../../../api/requests";
import {requestSubscription} from "../../../../api/userRequests.js";

function ProfileScreen(props) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [disabledStatus, setDisabledStatus] = useState(false);
    const [fullName, setFullName] = useState("");
    const [userName, setUserName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [vid, setVid] = useState("");

    useEffect(() => {
        document.title = "Account - Profile";
        userRequests(result =>  {
            setFullName(result.name);
            setUserName(result.username);
            setEmailAddress(result.email)
        });
    }, []);

    const openModal = (status) => {
        setDisabledStatus(true)
        setIsOpen(status);
    }

    const {user} = props;
    const now = new Date().getTime() / 1000;
    const subscriptionActiveUntil = parseInt(user.subscriptionActiveUntil);
    const subscriptionActive = subscriptionActiveUntil - now > 0;

    return <>
        <div className="ProfileScreen-container">
            <div className="ProfileScreen-mainContent">
                <p className="ProfileScreen-title">Profile</p>
                <div className="ProfileScreen-fieldContainer">
                    <p className="ProfileScreen-fieldKey">Full name</p>
                    <p className="ProfileScreen-fieldValue">{fullName}</p>
                </div>
                <div className="ProfileScreen-fieldContainer">
                    <p className="ProfileScreen-fieldKey">Username</p>
                    <p className="ProfileScreen-fieldValue">{userName}</p>
                </div>
                <div className="ProfileScreen-fieldContainer">
                    <p className="ProfileScreen-fieldKey">Email</p>
                    <p className="ProfileScreen-fieldValue">{emailAddress}</p>
                </div>
                <div className="ProfileScreen-fieldContainer">
                    <p className="ProfileScreen-fieldKey">Password</p>
                    <p className="ProfileScreen-fieldValue">
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                    </p>
                </div>
                
                <Link to="/account/profile/edit">
                    <button className="ProfileScreen-goToEdit">
                        Edit profile
                    </button>
                </Link> 
                <hr />
                <p className="ProfileScreen-deleteTitle">Delete your account</p>
                <p className="ProfileScreen-deleteInfo">
                    We're sorry to see you go!
                </p>
                {subscriptionActive && (
                <p className="ProfileScreen-deleteInfo">
                    You currently have an <b>active subscription</b>. Before deleting your account, please note that deleting your Freedrum account will not cancel your subscription.
                </p>
                )}
               {user.subscriptionSource === "Stripe" &&
               <p className="ProfileScreen-deleteInfo">
                   You can use the manage subscription button to cancel your subscription.
               </p>}
               {user.subscriptionSource === "Apple" &&
                <p className="ProfileScreen-deleteInfo">
                    You can go to the Apple App Store to cancel your subscription.
                </p>
               }
               {user.subscriptionSource === "Google" &&
               <p className="ProfileScreen-deleteInfo">
                   You can go to the Google Play Store to cancel your subscription.
               </p>
                }
                {!subscriptionActive && (
                <p className="ProfileScreen-deleteInfo">The account will no longer available and all data in the account
                    will be permanently deleted. This cannot be undone.</p>
                )}
                {!subscriptionActive && (
                    <button className="ProfileScreen-delete" onClick={() => {
                        doAuthenticatedRequest("POST", "/security/delete-account-step1", {})
                            .then((result) => {
                                setVid(result.data.vid);
                                openModal(true);
                            })
                    }}>
                        Delete account
                    </button>
                )}
                {subscriptionActive && (
                    <button className="ProfileScreen-manage" onClick={() => {
                        if (user.subscriptionSource === "Stripe") {
                            requestSubscription();
                        } else if (user.subscriptionSource === "Apple") {
                            window.location.href = "https://apps.apple.com/account/subscriptions";
                        } else if (user.subscriptionSource === "Google") {
                            window.location.href = "https://play.google.com/store/account/subscriptions";
                        } else {
                            if (window.confirm("Please contact support to remove your subscription")) {
                                window.location.href = "https://contact.gorgias.help/forms/vmv0yfw2";
                            }
                        }
                    }}>Manage Subscription</button>

                )}
            </div>
            <div>
                {modalIsOpen &&
                <DeleteAccountModal vid={vid} openModal={() => openModal(false)} setDisabledStatus={setDisabledStatus} email={emailAddress}/>}
            </div>
        </div>
        
    </>
}

export default ProfileScreen;
