import "../../pages/auth/registerscreen/registerscreenchallenge.scss";
import {AiOutlineInfoCircle, AiOutlineCloseCircle, AiOutlineCheckCircle,} from "react-icons/ai";
import {Link} from "react-router-dom";

import {ReactComponent as FreedrumLogo}                                 from "../../assets/images/freedrum-logo.svg";
import {ReactComponent as NammLogo}                                     from "../../assets/images/minc_logo.svg";
import FormInput                                                        from "../forminput/FormInput";

const FormContainer = ({validation, 
                        handleFormChange, 
                        handleTermsOfService, 
                        hasFocussedField, 
                        registerHandler, 
                        renderError, 
                        focusRef,
    }) => {
    return (<>
        <div className="RegisterScreenNamm-Container__InnerBox">
            <div className="RegisterScreenNamm-Container__InnerBox-TopArea__header">
                <div className="RegisterScreenNamm-Container__InnerBox-TopArea__header-freedrumLogoContainer">
                    <FreedrumLogo/>
                </div>
                <div className="RegisterScreenNamm-Container__InnerBox-TopArea__header-NammLogoContainer">
                    <NammLogo/>
                </div>
            </div>
            <hr style={{
                    color: '#F5F5F5',
                    backgroundColor: '#F5F5F5',
                    height: 0.5,
                    borderColor : '#F5F5F5',
                    marginBottom: "32px",
                    opacity:0.3,
                }}/>
            <div className="RegisterScreenNamm-Container__InnerBox-TopArea__FormContainer">
                <div className="RegisterScreenNamm-Container__InnerBox-TopArea__FormContainer-box">
                    <h2 className="RegisterScreenNamm-Container__InnerBox-TopArea__FormContainer-box-title">Join the Freedrum Challenge by signing up here</h2>
                    <div className="register__containerBox-formBox">
                        <form className="RegisterScreen-form" autoComplete="off" onSubmit={registerHandler} >
                            {renderError}
                            <FormInput name='name' handleFormChange={handleFormChange} title="Full name"
                                        placeholder="Your full name" type="text"
                                        valid={validation.name || !hasFocussedField.name} value={""} backgroundColor={"#f8f8f8"} focusRef = {focusRef}
                                    />
                            <FormInput name='email' handleFormChange={handleFormChange} title="Email"
                                        placeholder={"youremail@domain.com"} type="email"
                                        valid={validation.email || !hasFocussedField.email}
                                        value={""} backgroundColor={"#f8f8f8"} focusRef = {focusRef}/>
                            <div className="RegisterScreen-textBelowInputs-container">{validation.email ? 
                                <AiOutlineInfoCircle className="RegisterScreen-infoIcon" /> : <AiOutlineInfoCircle className="RegisterScreen-infoIcon" /> }
                                {validation.email ? <p className="RegisterScreen-textBelowInputs" >Please use a valid email address.</p> : <p className="RegisterScreen-textBelowInputs" >Please use a valid email address.</p> }
                            </div>
                            <FormInput name='password' handleFormChange={handleFormChange} title="Password"
                                        placeholder="Password" type="password"
                                        valid={validation.password || !hasFocussedField.password} value={""} backgroundColor={"#f8f8f8"} focusRef = {focusRef}/>
                            <div className="RegisterScreen-textBelowInputs-container password">
                                {validation.password ? 
                                <AiOutlineCheckCircle className="RegisterScreen-infoIcon" color="green"/> :
                                <AiOutlineCloseCircle className="RegisterScreen-infoIcon"/> }
                            <p className="RegisterScreen-textBelowInputs">Minimum 8 characters.</p>
                            </div>
                            
                            <div className="RegisterScreen-termsAndConditions">
                                <label className="RegisterScreen-termsAndConditions__label">
                                    <input type="checkbox"
                                            name="conditions"
                                            checked={validation.conditions}
                                            onChange={handleTermsOfService}
                                            id="terms_checkbox"
                                    />
                                    <div className="checkbox-custom"/>
                                    <p htmlFor="terms_checkbox">
                                        By signing up, you are agreeing to our Marketing Permissions,&nbsp;
                                        <span className="RegisterScreen-termsAndConditions__textLink">
                                            <Link to={{pathname: "https://freedrum.rocks/pages/terms-and-conditions"}}
                                                    target="_blank">Terms of Use</Link></span> and&nbsp;
                                        <span className="RegisterScreen-termsAndConditions__textLink"><Link
                                            to={{pathname: "https://freedrum.rocks/pages/privacy-notice"}} target="_blank">Privacy Policy</Link></span>.
                                    </p>

                                </label>
                            </div>
                            <button type="submit" className="RegisterScreen-registerButton" id="register-button">
                                Register
                            </button>
                        </form>
                    </div>
                </div>
            </div>           
         </div>
         <div className="RegisterPage__logIn">
                    <p>Already have a Freedrum account? 
                        <span className="RegisterPage__logIn-span">
                            <Link to="/challenge/login"> Log in here.</Link>
                        </span>
                    </p>
        </div> 
         <p className="footer-text">© {new Date().getFullYear()} Freedrum | <a href= "mailto:hello@freedrum.rocks">hello@freedrum.rocks</a> </p>
       </>
    )
};

export default FormContainer;