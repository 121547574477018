import axios from "axios";
import serverUrl from "../config";
import {getAccessToken, getRefreshToken, clearCredentials} from "./security";

var access_token_update = null;

export function updateAccessToken() {
    if (access_token_update !== null) {
        return access_token_update;
    } else {
        const refreshToken = getRefreshToken();

        if (refreshToken === null) {
            return Promise.reject(new Error("not-logged-in"));
        }

        access_token_update = doRequest("POST", "/security/get-access-token", {refreshToken: refreshToken})
            .then(result => {
                localStorage.setItem("access-token", result.data.accessToken);
                localStorage.setItem("access-token-expiration", result.data.accessTokenExpiration);
            })
            .catch(error => {
                clearCredentials();
                return Promise.reject(new Error("not-logged-in"));
            })
            .finally(() => {
                access_token_update = null;
            });

        return access_token_update;
    }
}

export function doRequest(method, path, params) {
    const parameters = {
        method: method,
        url: `${serverUrl}${path}`,
        data: params
    };
    return axios(parameters);
};

export function doAuthenticatedRequest(method, path, params) {
    const token = getAccessToken();

    const parameters = {
        method: method,
        url: `${serverUrl}${path}`,
        data: params
    };

    if (token === null) {
        return updateAccessToken()
            .then(() => {
                    parameters['headers'] = {
                        Authentication: getAccessToken()
                    };
                    return axios(parameters);
                });
    } else {
        parameters['headers'] = {
            Authentication: token
        };
        return axios(parameters);
    }
};

