import "./successbox.scss"
import {AiFillCheckCircle} from "react-icons/ai";
import {MdClose} from "react-icons/md"
import React from 'react'


// Text is a <p> element.
// wrap the text with a span for bold.

export default function SuccessBox({text, setSuccessStatus}) {

    const closeMessage = () => {
        setSuccessStatus(false)
    }

    return (
        <>
            <div className="SuccessBox-container"><AiFillCheckCircle size={40}/>
                <span className="SuccessBox-container__closeBtn" onClick={closeMessage}><MdClose size={16}/></span>
                {text}
            </div>
        </>
    )
}
