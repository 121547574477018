import "./instruction.scss";

import React from 'react'

//This component will be used in how to test our beta Screen.

export default function Instruction({ number, text }) {
    return (
        <div>
            <div className="Instruction-container">
                <div className="Instruction-number"><p>{number}</p></div>
                <div className="Instruction-info">{text}</div>
            </div>
        </div>
    )
}
