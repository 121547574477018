import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-TPMFDFG'
}

TagManager.initialize(tagManagerArgs);

ReactGA.initialize('G-F8KKX2BSM1');
ReactPixel.init('1951183538395710');
ReactPixel.pageView();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

