import {doRequest} from "./requests";
import {requestAllDevices} from "./deviceRequests";

export function saveCredentials (data, callback) {
    localStorage.setItem("access-token", data.accessToken);
    localStorage.setItem("refresh-token", data.refreshToken);
    localStorage.setItem("access-token-expiration", data.accessTokenExpiration);
    localStorage.setItem("refresh-token-expiration", data.refreshTokenExpiration);
};

export function clearCredentials() {
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("access-token-expiration");
    localStorage.removeItem("refresh-token-expiration");
}

export function isLoggedIn() {
    const refreshToken = localStorage.getItem("refresh-token");
    const refreshExpiration = new Date(localStorage.getItem("refresh-token-expiration"));
    const now = new Date();
    if (refreshToken !== null && refreshToken !== undefined && refreshExpiration - now > 86_400_000 * 7) {
        return true;
    }
    return false;
}

export function getAccessToken() {
    const now = new Date();
    const accessToken = localStorage.getItem("access-token");
    const accessExpiration = new Date(localStorage.getItem("access-token-expiration"));
    if (accessToken !== null && accessToken !== undefined && accessExpiration - now > 300_000) {
        return accessToken;
    }
    return null;
}

export function getRefreshToken() {
    const now = new Date();
    const refreshToken = localStorage.getItem("refresh-token");
    const refreshExpiration = new Date(localStorage.getItem("refresh-token-expiration"));
    if (refreshToken !== null && refreshToken !== undefined && refreshExpiration - now > 86_400_000 * 7) {
        return refreshToken;
    }
    return null;
}