import "./highscoredisplay.scss"

import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md';
import {Link} from "react-router-dom";
import {ReactComponent as FreedrumStudioLogo} from "../../assets/images/Logo_FD_Studio.svg";
import {doRequest} from "../../api/requests";
import {useState, useEffect, useLayoutEffect} from "react";
import facebook from "../../assets/images/Facebook_Icon.png";
import instagram from "../../assets/images/Instagram_Icon.png";
import tiktok from "../../assets/images/TikTok_Icon.png";
import youtube from "../../assets/images/YouTube_Icon.png";


const HighscoreDisplay = () => {

    const [scores, setScores] = useState([]);
    const [pageNumber, setPageNumber] = useState(5);
    const [screenSize, setScreenSize] = useState(1024);
    const [loading, setLoading] = useState(true);

    useLayoutEffect(() => {
        setScreenSize(window.innerWidth);
        window.addEventListener('resize', () => setScreenSize(window.innerWidth));
        return () => window.removeEventListener('resize', () => setScreenSize(window.innerWidth));
    }, []);

    useEffect(() => {
        document.title = "Freedrum DnB Challenge";
        loadData();
        const intervalId = setInterval(() => {
            loadData();
          }, 30000)
        return () => clearInterval(intervalId);
    }, [setScores, setLoading, pageNumber])

    const loadData = () => {
        doRequest("GET", "/scoreboard/" + pageNumber).then(result => {
            setScores(result.data);
            setLoading(false);
        }).catch(error => {});
    }

    return <>
            <div className="Highscore-container">
                <div className="Highscore-container__Box">
                    <div className="Highscore-container__InnerBox-TopArea__header">
                       <FreedrumStudioLogo className="freedrumStudioLogo"/>
                    </div>
                    <h1 className="Highscore-container__Box__title">FREEDRUM DnB CHALLENGE</h1>
                    <div className="Highscore-container__Box__DayChanger"></div>
                    <div className="Highscore-container__Box__ScoreContainer">
                        {
                            loading ?
                                <div className="Highscore-container__Box__ScoreContainer_Firstitem"></div> :
                                scores.length === 0 ?
                                    <div className="Highscore-container__Box__ScoreContainer_Firstitem">
                                        <p className="Highscore-container__Box__ScoreContainer_Firstitem__NoItem">Scoreboard is empty</p>
                                    </div> :
                                    scores.length === 1 ?
                                        <a href={scores[0].url}>
                                            <div className="Highscore-container__Box__ScoreContainer_Firstitem">
                                                <div className="Highscore-container__Box__ScoreContainer_Firstitem_number">#1</div>
                                                <div className="Highscore-container__Box__ScoreContainer_Firstitem_name">{scores[0].name}</div>
                                                <div className="Highscore-container__Box__ScoreContainer_Firstitem_score">{scores[0].score} p</div>
                                            </div>
                                        </a>
                                        :
                                            <>
                                            <a href={scores[0].url}>
                                                <div className="Highscore-container__Box__ScoreContainer_Firstitem">
                                                    <div className="Highscore-container__Box__ScoreContainer_Firstitem_number">#1</div>
                                                    <div className="Highscore-container__Box__ScoreContainer_Firstitem_name">{scores[0].name}</div>
                                                    <div className="Highscore-container__Box__ScoreContainer_Firstitem_score">{scores[0].score} p</div>
                                                </div>
                                            </a>
                                            <div className="Highscore-container__Box__ScoreContainer_items">
                                                {
                                                    scores.map(function(object, i) {
                                                        if(i !== 0) {
                                                            return <a href={object.url}  className="Highscore-container__Box__ScoreContainer_items-container" key={i}>
                                                                        <div className="Highscore-container__Box__ScoreContainer_items_number">#{i+1}</div>
                                                                        <div className="Highscore-container__Box__ScoreContainer_items_name">{object.name}</div>
                                                                        <div className="Highscore-container__Box__ScoreContainer_items_score">{object.score} p</div>
                                                                   </a>
                                                        }
                                                    })
                                                }
                                            </div>
                                            </>
                        }
                    </div>
                    <h1 className="Highscore-container__Box__title">CONTEST ENDS IN</h1>
                    <div className="Highscore-container__Box__Timer">
                        <table align="center"><tbody><tr><td><img src="https://gen.sendtric.com/countdown/j3xhp0jw4i" /></td></tr></tbody></table>
                    </div>
                </div>
                <div className="">
                    <img className="Highscore-container__Box__social_media" src={facebook} alt="Facebook" onClick={()=> window.open("https://www.facebook.com/freedrumrocks/", "_blank")} />
                    <img className="Highscore-container__Box__social_media"src={instagram} alt="Instagram" onClick={()=> window.open("https://www.instagram.com/freedrumrocks/", "_blank")} />
                    <img className="Highscore-container__Box__social_media"src={tiktok} alt="TikTok" onClick={()=> window.open("https://www.tiktok.com/@freedrumrocks/", "_blank")}  />
                    <img className="Highscore-container__Box__social_media"src={youtube} alt="YouTube" onClick={()=> window.open("https://www.youtube.com/c/FreedrumRocks/", "_blank")} />
                </div>
                <p className="footer-text">© {new Date().getFullYear()} Freedrum Studio | <a href= "mailto:hello@freedrum.rocks">hello@freedrum.rocks</a></p>
            </div>
           </>
};

export default HighscoreDisplay;