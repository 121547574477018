import "./registerscreenchallenge.scss";
import {useState, useEffect, useRef, useCallback} from "react";
import { hotjar } from 'react-hotjar';

import ReactGA from "react-ga4";

import {doRequest}                                                      from "../../../api/requests";
import {handleError}                                                    from "../../../api/errorHandling";
import {emailValidation, nameValidation, passwordValidation, isValid}   from "../../../components/forminput/validation";
import FormContainer                                                    from "../../../components/register/FormContainerChallengeRegister";
import SuccessRegisterChallenge                                           from "../../../components/register/SuccessRegisterChallenge";

import VerifyEmailModal from "./VerifyEmailModal";

const RegisterScreenChallenge = (loggedInUser) => {

    const [user, setUser] = useState({name: '', email: '', password: ''});
    const [validation, setValidation] = useState({name: false, email: false, password: false, conditions: false})
    const [hasFocussedField, setHasFocussedField] = useState({
        name: false,
        email: false,
        password: false,
        conditions: false
    });
    const [successRegister, setSuccessRegister] = useState(false);

    const focusRef = useRef();

    const [modalIsOpen, setIsOpen] = useState(false);

    const [errorCode, setErrorCode] = useState(null);
    let renderError;
    

     //== CHANGE THE PAGE TITLE ==//

     useEffect( () => {
        document.title = "Freedrum Challenge - Register";
    })

    var isSuccess = loggedInUser.user.eventCode !== undefined;
    if (successRegister != isSuccess) {
        setSuccessRegister(isSuccess);
    }


       // Function to send data to backend and activate modal when response is success.
       const registerHandler = (e) => {
        e.preventDefault();

        setHasFocussedField({...hasFocussedField, email: true, password: true});

        if (!isValid(validation)) {
            if (!validation.email) {
                setErrorCode("email-address-invalid");
            } else if (!validation.password) {
                setErrorCode("password-short");
            } else if (!validation.conditions) {
                setErrorCode("accept-terms-missing");
            } else {
                setErrorCode("form-missing-fields");
            }
            return;
        }

        setErrorCode(null);

        doRequest("POST", "/security/register/event", {email: user.email, password: user.password, name: user.name})
            .then(result => {
                ReactGA.event({
                    category: 'Account',
                    action: 'Created account',
                });
                if (!result.data.verified) {
                    user.email = result.data.email;
                    setIsOpen(true);
                }
            })
            .catch(error => setErrorCode(error));
    };

    if (errorCode) {
        renderError = handleError(errorCode, null);
    }

    const handleTermsOfService = (input) => {
        setValidation({...validation, [input.target.name]: !validation.conditions});
    }

    const callback = () => {
        window.location.reload();
    }

    // User input handling - Show error when they input wrong email format, password less than 8 char, or empty name. CTA button also being disable.
    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setErrorCode(null);
        setHasFocussedField({...hasFocussedField, [inputName]: true});

        if (inputName === 'name') {
            setUser({...user, [inputName]: inputValue});
            if (nameValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        if (inputName === 'email') {
            setUser({...user, [inputName]: inputValue});
            if (emailValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        if (inputName === 'password') {
            setUser({...user, [inputName]: inputValue});
            if (passwordValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }
    };

    return <>
    <div className="register__contentBox">

        {successRegister ? <SuccessRegisterChallenge user={loggedInUser.user} /> : <FormContainer registerHandler = {registerHandler}
                                                                handleTermsOfService = {handleTermsOfService}
                                                                validation = {validation}
                                                                hasFocussedField = {hasFocussedField}
                                                                handleFormChange = {handleFormChange}
                                                                renderError = {renderError}
                                                                focusRef = {focusRef}
                                                    /> }
        
        {modalIsOpen && <VerifyEmailModal email={user.email} callback={callback} />}
    </div>
 </>
}

export default RegisterScreenChallenge;