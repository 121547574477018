import "../../pages/auth/registerscreen/registerscreenwithinfo.scss";
import React from "react";
import devicesIcons                                                     from "../../assets/images/device-list-icons.png";
import appleStore from '../../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import googleStore from '../../assets/images/google-play-badge.svg';
import macOSButton from '../../assets/images/Download_for_MacOS_button.svg';
import windowsButton from '../../assets/images/Download_for_Windows_button.svg';

const DeviceListData = (parameter) => {
    return (
        <div className="RegisterScreenUpdate-DeviceListsContainer">
            <div className="RegisterScreenUpdate-DeviceListsContainer__ContentBox"> 
                <h1 className="RegisterScreenUpdate-DeviceListsContainer__ContentBox-Title">{parameter['text']}</h1>
             {/*    <img className="RegisterScreenUpdate-DeviceListsContainer__ContentBox-image"
                          src={devicesIcons} alt=""/> */}
            <div className="RegisterScreenUpdate-DeviceListsContainer__ContentBox-image">
                <img className="SubscriptionScreen-mainContent__DownloadLink-iOS" src={appleStore} alt="apple-app-store" onClick={()=> window.open("https://apps.apple.com/us/app/freedrum-2/id1551286191", "_blank")} />
                <img className="SubscriptionScreen-mainContent__DownloadLink-Google"src={googleStore} alt="google-store" onClick={()=> window.open("https://play.google.com/store/apps/details?id=rocks.freedrum.app&hl=en&gl=US", "_blank")} />
                <img className="SubscriptionScreen-mainContent__DownloadLink-MacOSButton"src={macOSButton} alt="macOS-download" onClick={()=> window.open("/downloads/desktop-app/Freedrum_latest.dmg", "_blank")}  />
                <img className="SubscriptionScreen-mainContent__DownloadLink-WindowsButton"src={windowsButton} alt="windows-download" onClick={()=> window.open("/downloads/desktop-app/windows/Freedrum_latest.exe", "_blank")} />
            </div>
           
            </div>
        </div>
    )
};

const DeviceList = React.memo(DeviceListData);

export default DeviceList;