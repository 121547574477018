import "../registerscreen/registerscreenfromshopify.scss";
import {Link} from "react-router-dom";
import {useState, useEffect} from "react";
import {AiOutlineInfoCircle, AiOutlineCloseCircle, AiOutlineCheckCircle,} from "react-icons/ai";
import {Redirect} from "react-router";
import ReactGA from "react-ga4";
import {saveCredentials}                                from "../../../api/security";

import {doRequest} from "../../../api/requests";
import {handleError} from "../../../api/errorHandling";
import FormInput from "../../../components/forminput/FormInput";
import {emailValidation, passwordValidation, promoCodeValidation, isValid} from "../../../components/forminput/validation";
import {ReactComponent as FreedrumStudioLogo} from "../../../assets/images/Logo_FD_Studio.svg";
import {ReactComponent as AmazonLogo} from "../../../assets/images/AmazonLogo.svg";

import VerifyEmailModal from "../registerscreen/VerifyEmailModal";

const LoginScreenAmazon = () => {

    const [user, setUser] = useState({email: '', password: '', promocode: ''});
    const [validation, setValidation] = useState({email: false, password: false, promocode: false})
    const [hasFocussedField, setHasFocussedField] = useState({
        email: false,
        password: false,
        promocode: false,
    });
    const [promotionActive, setPromotionActive] = useState(false)

    const [modalIsOpen, setIsOpen] = useState(false);

    const [errorCode, setErrorCode] = useState(null);
    const [redirect, setRedirect] = useState(false);

    let renderError;

    useEffect(()=> {
        document.title = "Freedrum Studio - Log In";
    });

    const loginHandler = (e) => {
        e.preventDefault();

        setHasFocussedField({...hasFocussedField, email: true, password: true});

        if (!isValid(validation)) {
            if (!validation.email) {
                setErrorCode("email-invalid");
            } else if (!validation.password) {
                setErrorCode("password-short");
            } else if (!validation.promocode) {
                setErrorCode("empty-promocode");
            } else {
                setErrorCode("form-missing-fields");
            }
            return;
        }

        setErrorCode(null);

        doRequest("POST", "/security/signin/amazon", {email: user.email, password: user.password, promoCode: user.promocode})
            .then(result => {
                if (result !== undefined) {
                    saveCredentials(result.data, () => {
                        setRedirect(true);
                    });
                    setRedirect(true);
                }
            })
            .catch(error => setErrorCode(error));
    }

    if (errorCode) {
        renderError = handleError(errorCode, null);
    }

    if (redirect) {
        return window.location.replace("https://freedrum.studio/thank-you-amazon.html");
    }

    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setErrorCode(null);
        setHasFocussedField({...hasFocussedField, [inputName]: true});

        if (inputName === 'email') {
            setUser({...user, [inputName]: inputValue});
            if (emailValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        if (inputName === 'password') {
            setUser({...user, [inputName]: inputValue});
            if (passwordValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        if (inputName === 'promocode') {
            var promoCode = inputValue.trim();
            setUser({...user, [inputName]: promoCode});
            if (promoCodeValidation(promoCode)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }
    };

    return <>
       <div className="register__contentBox">
           <div className="register__contentBox-header">
                <div className="register__containerBox-freedrumLogoContainer">
                    <FreedrumStudioLogo className="freedrumStudioLogo"/>
                    <AmazonLogo className="amazonLogo"/>
                </div>
           </div>
           
            <div className="register__containerBox">
               
                <h1 className="register__containerBox-title">Log In</h1>
                <p className="register__containerBox-text">Redeem your exclusive trial by logging into your Freedrum Studio account.</p>
                {renderError}
                <div className="register__containerBox_formBox">
                    <form className="RegisterScreen-form" autoComplete="off" onSubmit={loginHandler}>
                        <FormInput name='email' handleFormChange={handleFormChange} title="Email"
                                    placeholder={"youremail@domain.com"} type="email"
                                    valid={validation.email || !hasFocussedField.email}
                                    value={""} backgroundColor={"#f8f8f8"}/>
                        <div className="RegisterScreen-textBelowInputs-container">
                            <AiOutlineInfoCircle className="RegisterScreen-infoIcon"/>
                            <p className="RegisterScreen-textBelowInputs">Please use a valid email address.</p>
                        </div>
                        <FormInput name='password' handleFormChange={handleFormChange} title="Password"
                                    placeholder="Password" type="password"
                                    valid={validation.password || !hasFocussedField.password} value={""} backgroundColor={"#f8f8f8"}/>
                        <p className="RegisterScreen-textBelowInputs password">{validation.password ?
                            <AiOutlineCheckCircle className="RegisterScreen-wrongPasswordIcon" color="green"/> :
                            <AiOutlineInfoCircle className="RegisterScreen-wrongPasswordIcon" color="#696969"/>
                        }
                            Minimum 8 characters.
                        </p>
                        <FormInput name='promocode' handleFormChange={handleFormChange} title="Promo Code"
                                    placeholder="Enter 6 or 8 digit number" type="text"
                                    valid={validation.promocode || !hasFocussedField.promocode} value={""} backgroundColor={"#f8f8f8"}/>
                        <div className="RegisterScreen-textBelowInputs-container">
                            <AiOutlineInfoCircle className="RegisterScreen-infoIcon"/>
                            <p className="RegisterScreen-textBelowInputs">Please enter the promo code sent to your email.</p>
                        </div>            
                        <button type="submit" className="RegisterScreen-registerButton" id="register-button">
                            Login
                        </button>
                    </form>
                </div> 
                <p className="register__containerBox-information">
                </p>

                <p className="RegisterScreen-askLogin">Do not have an account?
                        <span className="RegisterScreen-askLoginButton"
                        > <Link to="/amazon/register"> Register</Link></span>
                </p>
                
            </div>
            {modalIsOpen && <VerifyEmailModal email={user.email} callback={() => setRedirect(true)} />}
       </div>
    </>
}

export default LoginScreenAmazon;