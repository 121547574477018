import './forminput.scss'
import {useState, useEffect} from "react";

function FormInput({title, placeholder, type, handleFormChange, name, valid, backgroundColor, focusRef, disabledStatus, value}) {

    const [inputValue, setInputValue] = useState("");

    useEffect( () => {
        if (value !== undefined) {
            setInputValue(value)
        }
    }, [value])

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        handleFormChange(e.target);
    };

    return (
        <div ref={focusRef} className="FormInput-nameField"
             style={{backgroundColor:backgroundColor, border: valid ? '' : '1px solid #ff1744'}}>
            <label className="FormInput-nameLabel"
                   htmlFor="register-name"
                   style= {{backgroundColor:backgroundColor}}>
                {title}
            </label>
            {type === 'textarea'
                ? <textarea
                    name={name}
                    value={inputValue}
                    onChange={handleInputChange}
                    title={title}
                    placeholder={placeholder}
                    className="FormInput-nameInput-textarea"
                    disabled={disabledStatus ? "disabled" : null}/>
                : <input
                    name={name}
                    onChange={handleInputChange}
                    value={inputValue}
                    className="FormInput-nameInput"
                    type={type}
                    title={title}
                    placeholder={placeholder}
                    disabled={disabledStatus ? "disabled" : null}/>}
        </div>
    );
}

export default FormInput;
