import "../../pages/auth/registerscreen/registerscreenchallenge.scss";
import {ReactComponent as FreedrumLogo}                                 from "../../assets/images/freedrum-logo.svg";
import {ReactComponent as NammLogo}                                     from "../../assets/images/minc_logo.svg";

import appleStore                                                       from '../../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import googleStore                                                      from '../../assets/images/google-play-badge.svg';
import macOSButton                                                      from '../../assets/images/Download_for_MacOS_button.svg';
import windowsButton                                                    from '../../assets/images/Download_for_Windows_button.svg';

const SuccessRegisterNamm = (user) => {
console.log(user.user);
    return <>
            <div className="RegisterScreenNamm-Container__InnerBox">
                <div className="RegisterScreenNamm-Container__InnerBox-TopArea__header">
                    <div className="RegisterScreenNamm-Container__InnerBox-TopArea__header-freedrumLogoContainer">
                        <FreedrumLogo/>
                    </div>
                    <div className="RegisterScreenNamm-Container__InnerBox-TopArea__header-NammLogoContainer">
                        <NammLogo/>
                    </div>
                </div>
                <hr style={{
                        color: '#F5F5F5',
                        backgroundColor: '#F5F5F5',
                        height: 0.5,
                        borderColor : '#F5F5F5',
                        marginBottom: "32px",
                        opacity:0.3,
                    }}
                />
                <div className="RegisterScreenNamm-Container__InnerBox__TextBox">
                    <p className="RegisterScreenNamm-Container__InnerBox__TextBox_Title">It's Done!</p>
                    <p className="RegisterScreenNamm-Container__InnerBox__TextBox_Copy">You’re all set to join the <br />Freedrum Challenge.</p>
                    <h2 className="RegisterScreenNamm-Container__InnerBox__TextBox_Numbers">{user.user !== undefined ? String(user.user.eventCode).padStart(4, '0') : ""}</h2>
                    <p className="RegisterScreenNamm-Container__InnerBox__TextBox_Copy">Show this page to one of our team members at the Freedrum booth to participate.</p>
                </div>
                <hr style={{
                        color: '#F5F5F5',
                        backgroundColor: '#F5F5F5',
                        height: 0.5,
                        borderColor : '#F5F5F5',
                        marginBottom: "32px",
                        opacity:0.3,
                    }}
                />
                <div className="RegisterScreenNamm-Container__InnerBox__LinkBox">
                    <img className="SubscriptionScreen-mainContent__DownloadLink-iOS" src={appleStore} alt="apple-app-store" onClick={()=> window.open("https://apps.apple.com/us/app/freedrum-2/id1551286191", "_blank")} />
                    <img className="SubscriptionScreen-mainContent__DownloadLink-Google"src={googleStore} alt="google-store" onClick={()=> window.open("https://play.google.com/store/apps/details?id=rocks.freedrum.app&hl=en&gl=US", "_blank")} />
                    <img className="SubscriptionScreen-mainContent__DownloadLink-MacOSButton"src={macOSButton} alt="macOS-download" onClick={()=> window.open("/downloads/desktop-app/Freedrum_latest.dmg", "_blank")}  />
                    <img className="SubscriptionScreen-mainContent__DownloadLink-WindowsButton"src={windowsButton} alt="windows-download" onClick={()=> window.open("/downloads/desktop-app/windows/Freedrum_latest.exe", "_blank")} />
                </div>
            </div>
            <p className="footer-text success">© {new Date().getFullYear()} Freedrum | <a href= "mailto:hello@freedrum.rocks">hello@freedrum.rocks</a> </p>
            </>
}

export default SuccessRegisterNamm;