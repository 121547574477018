import './subscriptionstates.scss';
import React from 'react';
import {requestSubscription} from "../../../../api/userRequests.js";
import appleStore from '../../../../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import googleStore from '../../../../assets/images/google-play-badge.svg';
import macOSButton from '../../../../assets/images/Download_for_MacOS_button.svg';
import windowsButton from '../../../../assets/images/Download_for_Windows_button.svg';
import ManageModal from "../subcription-states/ManageSubscriptionsModal";

function formattedDate(ts) {
    const date = new Date(ts * 1000);

    return date.toLocaleDateString();
}

class SubscriptionActive extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modalStatus: false
        };

        this.manageSubscription = this.manageSubscription.bind(this);
        this.changeModalStatus = this.changeModalStatus.bind(this);
    }

    changeModalStatus(status) {
        this.setState({modalStatus: status});
    }

    manageSubscription() {
        const {user} = this.props;
        if (user.subscriptionSource === "Stripe") {
            requestSubscription();
        } else if (user.subscriptionSource === "Apple") {
            window.location.href = "https://apps.apple.com/account/subscriptions";
        } else if (user.subscriptionSource === "Google") {
            window.location.href = "https://play.google.com/store/account/subscriptions";
        } else {
            this.setState({modalStatus: true});
        }
    }

    render() {
        const {user} = this.props;

        return <>
            <div className="SubscriptionScreenActive-mainContent__Banner">
                <div className="SubscriptionScreenActive-mainContent__Banner-Image"></div>
            </div>
            <div className="SubscriptionScreen-mainContent__SubscriptionInfo">
                <div className="SubscriptionScreen-mainContent__SubscriptionInfo__TitleButton">
                     <h2 className="SubscriptionScreen-mainContent__SubscriptionInfo__TitleButton-Title">Current Subscription</h2>
                     <button className="SubscriptionScreen-mainContent__SubscriptionInfo__TitleButton-Button" onClick={this.manageSubscription}>Manage Subscription</button>
                </div>
                <div className="SubscriptionScreen-mainContent__SubscriptionInfo__InfoBox">
                    <div className="SubscriptionScreen-mainContent__SubscriptionInfo__InfoBox__activeSubRow">
                        <p className="SubscriptionScreen-mainContent__SubscriptionInfo__InfoBox__activeSubRow-SubsType">{(user.subscriptionStatus === "Cancelled" ? "Cancelled" : user.subscriptionPlan)}</p>
                        <p className="SubscriptionScreen-mainContent__SubscriptionInfo__InfoBox__activeSubRow-SubsPrice">{user.subscriptionStatus === "Cancelled" ? "" : user.nextPaymentAmount}</p>
                    </div>
                    <hr/>
                    <div className="SubscriptionScreen-mainContent__SubscriptionInfo__InfoBox__NextPaymentRow">
                        <p className="SubscriptionScreen-mainContent__SubscriptionInfo__InfoBox__NextPaymentRow-NextBillText">{user.subscriptionStatus === "Cancelled" ? "Last Active Date" : "Next Billing Date"}</p>
                        <p className="SubscriptionScreen-mainContent__SubscriptionInfo__InfoBox__NextPaymentRow-NextBillDate">{user.subscriptionStatus === "Cancelled" ?
                                    formattedDate(user.subscriptionActiveUntil) :
                                    user.nextPaymentAmount + " on " + formattedDate(user.nextPaymentDate)}</p>
                    </div>
                </div>
            </div>
            <div className="SubscriptionScreen-mainContent__DownloadLink">
                <h2 className="SubscriptionScreen-mainContent__DownloadLink-Title">Freedrum Studio is available for Android, iOS, macOS and Windows</h2>
                <img className="SubscriptionScreen-mainContent__DownloadLink-iOS" src={appleStore} alt="apple-app-store" onClick={()=> window.open("https://apps.apple.com/us/app/freedrum-2/id1551286191", "_blank")} />
                <img className="SubscriptionScreen-mainContent__DownloadLink-Google"src={googleStore} alt="google-store"onClick={()=> window.open("https://play.google.com/store/apps/details?id=rocks.freedrum.app&hl=en&gl=US", "_blank")} />
                <img className="SubscriptionScreen-mainContent__DownloadLink-MacOSButton"src={macOSButton} alt="macOS-download" onClick={()=> window.open("/downloads/desktop-app/Freedrum_latest.dmg", "_blank")} />
                <img className="SubscriptionScreen-mainContent__DownloadLink-WindowsButton"src={windowsButton} alt="windows-download" onClick={()=> window.open("/downloads/desktop-app/windows/Freedrum_latest.exe", "_blank")} />
            </div>

            {this.state.modalStatus && <ManageModal  modalStatusChange={this.changeModalStatus} />}
        </>
    }

}

export default SubscriptionActive;
