import './registerscreenwithinfo.scss';
import {useState, useEffect, useRef, useCallback} from "react";
import { hotjar } from 'react-hotjar';

import ReactGA from "react-ga4";

import {doRequest}                                                      from "../../../api/requests";
import {handleError}                                                    from "../../../api/errorHandling";
import {emailValidation, nameValidation, passwordValidation, isValid}   from "../../../components/forminput/validation";
import FAQData                                                          from "../../../components/register/data";
import FAQList                                                          from "../../../components/register/FAQList";
import RegisterFooter                                                   from "../../../components/register/RegisterFooter";
import ArtworkContainer                                                 from "../../../components/register/ArtworkContainer";
import DeviceListsContainer                                             from "../../../components/register/DeviceListContainer";
import PricingContainer                                                 from "../../../components/register/PricingContainer";
import SubscriptionInfoContainer                                        from "../../../components/register/SubscriptionInfoContainer";
import FormContainer                                                    from "../../../components/register/FormContainer";

import VerifyEmailModal from "./VerifyEmailModal";


const RegisterScreenWithInfo = ({callback}) => {
    const [user, setUser] = useState({name: '', email: '', password: ''});
    const [validation, setValidation] = useState({name: false, email: false, password: false, conditions: false})
    const [hasFocussedField, setHasFocussedField] = useState({
        name: false,
        email: false,
        password: false,
        conditions: false
    });

    const focusRef = useRef();

    const [modalIsOpen, setIsOpen] = useState(false);

    const [errorCode, setErrorCode] = useState(null);
    let renderError;

    //== HOTJAR SETTINGS ==//

    useEffect( () => {
        hotjar.initialize(2880958, 6);

        // Identify the user
        hotjar.identify('USER_ID', { userProperty: 'value' });
        
        // Add an event
        hotjar.event('button-click');
    
        // Update SPA state
        hotjar.stateChange('/register');

        
    }, [])

     


    //== CHANGE THE PAGE TITLE ==//

    useEffect( () => {
        document.title = "Freedrum - Account Register";
    })

    // Function to send data to backend and activate modal when response is success.
    const registerHandler = (e) => {
        e.preventDefault();

        setHasFocussedField({...hasFocussedField, email: true, password: true});

        if (!isValid(validation)) {
            if (!validation.email) {
                setErrorCode("email-address-invalid");
            } else if (!validation.password) {
                setErrorCode("password-short");
            } else if (!validation.conditions) {
                setErrorCode("accept-terms-missing");
            } else {
                setErrorCode("form-missing-fields");
            }
            return;
        }

        setErrorCode(null);

        doRequest("POST", "/security/register", {email: user.email, password: user.password, name: user.name})
            .then(result => {
                ReactGA.event({
                    category: 'Account',
                    action: 'Created account',
                });
                if (!result.data.verified) {
                    user.email = result.data.email;
                    setIsOpen(true);
                }
            })
            .catch(error => setErrorCode(error));
    };

    if (errorCode) {
        renderError = handleError(errorCode, null);
    }

    const handleTermsOfService = (input) => {
        setValidation({...validation, [input.target.name]: !validation.conditions});
    }

    // User input handling - Show error when they input wrong email format, password less than 8 char, or empty name. CTA button also being disable.
    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setErrorCode(null);
        setHasFocussedField({...hasFocussedField, [inputName]: true});

        if (inputName === 'name') {
            setUser({...user, [inputName]: inputValue});
            if (nameValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        if (inputName === 'email') {
            setUser({...user, [inputName]: inputValue});
            if (emailValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        if (inputName === 'password') {
            setUser({...user, [inputName]: inputValue});
            if (passwordValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }
    };

    //== SCROLL TO TOP FUNCTION ==//

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
    }

    return <>
    <div className="RegisterScreenUpdate-container">
        <FormContainer registerHandler = {registerHandler}
                       handleTermsOfService = {handleTermsOfService}
                       validation = {validation}
                       hasFocussedField = {hasFocussedField}
                       handleFormChange = {handleFormChange}
                       renderError = {renderError}
                       focusRef = {focusRef}
        />
        {modalIsOpen && <VerifyEmailModal email={user.email} callback={callback} />}
    </div>
    </>

}

export default RegisterScreenWithInfo;