import './sidenavbar.scss'
import {NavLink} from "react-router-dom";
import FreedrumLogo from '../../assets/images/freedrum-logo.svg'
import {Redirect, Link} from "react-router-dom";
import {MdClose} from "react-icons/md";
import {useState} from 'react';
import {logOut} from "../../api/userRequests";
import { HiExternalLink } from "react-icons/hi";

function SideNavbar({logout, history}) {

    if (
    history.location.pathname === "/challenge" ||
    history.location.pathname === "/challenge/" ||
    history.location.pathname === "/challenge/login" ||
    history.location.pathname === "/challenge/login/" ||
    history.location.pathname === "/highscore/admin" ||
    history.location.pathname === "/highscore/admin/" ||
    history.location.pathname === "/highscore" ||
    history.location.pathname === "/highscore/"
    ) {
        return (<></>);
    }

    return (
        <div id="SideNavbar-content">
            <div className="SideNavbar-container">
                <span className="SideNavbar-container__closeBtn" onClick={mobileNavHandler}><MdClose size={16}/></span>
                <div className="SideNavbar-freedrumLogo">
                    <Link to="">
                        <img src={FreedrumLogo} alt="Freedrum logo"/>
                    </Link>
                </div>
                {/* <div className="sidebar-options-box"> */}
                    <NavLink to="/account/subscription"
                            activeClassName="SideNavbar-active"
                            exact>
                        <div className="SideNavbar-item">
                            <p className="SideNavbar-mainItem">Subscription</p>
                        </div>
                    </NavLink>

                    <NavLink to="/account/profile"
                            activeClassName="SideNavbar-active">
                        <div className="SideNavbar-item">
                            <p className="SideNavbar-mainItem">Profile</p>
                        </div>
                    </NavLink>

                    <NavLink to={{pathname:"https://freedrum.rocks/pages/faq"}} target="_blank"
                            activeClassName="SideNavbar-active">
                        <div className="SideNavbar-item">
                            <p className="SideNavbar-mainItem">FAQ <HiExternalLink className="text-icon" size="15px" /></p>
                        </div>
                    </NavLink>

                    <NavLink /* to="/account/tutorials" */ to={{pathname:"https://freedrum.rocks/pages/setup-page"}} target="_blank"
                            activeClassName="SideNavbar-active">
                        <div className="SideNavbar-item">
                            <p className="SideNavbar-mainItem">Tutorials <HiExternalLink className="text-icon" size="15px" /></p>
                        </div>
                    </NavLink>

                <button className="SideNavbar-logoutBtn" onClick={logout}>
                        Log out
                    </button>

                <div className="SideNavbar-footer">
                    <p>
                        Copyright © {new Date().getFullYear()}, Freedrum. <br/>
                        Freedrum is Registered in the U.S. Patent and Trademark Office
                    </p>
                </div>
            </div>
            <div className="SideNavbar-overlay" onClick={mobileNavHandler}/>
        </div>
    );
}

function mobileNavHandler() {
    let element = document.getElementById("SideNavbar-content");
    if (element.className === 'SideNavbar-content-visible') {
        element.className = '';
    } else {
        element.className = 'SideNavbar-content-visible';
    }
}

export default SideNavbar;