import './loginscreen.scss';

import {Link, Redirect} from "react-router-dom";
import {useState, useRef, useEffect} from "react";

import FormInput                                        from "../../../components/forminput/FormInput";
import ForgotPasswordModal                              from "../../../components/forgot-password/ForgotPasswordModal";
import {emailValidation, passwordValidation, isValid}   from "../../../components/forminput/validation";
import {doRequest}                                      from "../../../api/requests";
import {saveCredentials}                                from "../../../api/security";
import {handleError}                                    from "../../../api/errorHandling";
import phonesPicture                                    from "../../../assets/images/HeroImage-App.png";
import phonesPictureWebP                                from "../../../assets/images/HeroImage-App.webp";
import {ReactComponent as FreedrumLogo}                 from "../../../assets/images/freedrum-logo.svg";

import VerifyEmailModal                                 from '../registerscreen/VerifyEmailModal';

function LoginScreen({callback}) {
    const [user, setUser] = useState({email: '', password: ''});
    const [hasFocussedField, setHasFocussedField] = useState({email: false, password: false})
    const [validation, setValidation] = useState({email: false, password: false})
    const blurElement = useRef(null);

    const [errorCode, setErrorCode] = useState(null);
    const [redirect, setRedirect] = useState(false);

    const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
    const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
    const [disabledStatus, setDisabledStatus] = useState(false);

    let renderError;

    useEffect(() => {
        document.title = "Login";
        if (!isForgotPasswordModalOpen && !isVerifyEmailModalOpen) {
            setDisabledStatus(false);
        }
    }, [isForgotPasswordModalOpen, isVerifyEmailModalOpen, setDisabledStatus])

    const loginHandler = (e) => {
        e.preventDefault();

        if (disabledStatus) { return; }

        setHasFocussedField({...hasFocussedField, email: true, password: true});

        if (!isValid(validation)) {
            if (!validation.email) {
                setErrorCode("email-invalid");
            } else if (!validation.password) {
                setErrorCode("password-short");
            } else {
                setErrorCode("form-missing-fields");
            }
            return;
        }

        setErrorCode(null);

        doRequest("POST", "/security/signin", {email: user.email, password: user.password})
            .then(result => {
                if (result !== undefined) {
                    saveCredentials(result.data, () => {
                        setRedirect(true)
                    });
                    callback();
                }
            })
            .catch(error => setErrorCode(error));
    }

    if (errorCode != null) {
        renderError = handleError(errorCode, () => {
            setDisabledStatus(true);
            setIsVerifyEmailModalOpen(true);
        })
    }

    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setErrorCode(null);
        setHasFocussedField({...hasFocussedField, [inputName]: true});

        if (inputName === 'email') {
            setUser({...user, [inputName]: inputValue});
            if (emailValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
        }
        if (inputName === 'password') {
            setUser({...user, [inputName]: inputValue});
            if (passwordValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
        }

        setUser({...user, [inputName]: inputValue});
    }

    const closeModalClicked = () => {
        setDisabledStatus(true)
        setIsForgotPasswordModalOpen(false);
        setIsVerifyEmailModalOpen(false);
    }

    return <>
        <div className="LoginScreen-container">
            <div className="LoginScreen-freedrumLogoContainer">
                <FreedrumLogo/>
            </div>
            <div className="LoginScreen-container__section">
                <div className="LoginScreen-formContainer">
                    <h1 className="LoginScreen-formContainer-title">Log in to access your Freedrum account.</h1>
                    <form ref={blurElement} className="LoginScreen-form" autoComplete="off" onSubmit={loginHandler}>
                        {renderError}
                        <FormInput handleFormChange={handleFormChange} name='email' title="Email"
                                   placeholder="youremail@domain.com" type="email"
                                   valid={validation.email || !hasFocussedField.email}
                                   disabledStatus={disabledStatus} value={""}/>
                        <FormInput handleFormChange={handleFormChange} name='password' title="Password"
                                   placeholder="Password" type="password"
                                   valid={validation.password || !hasFocussedField.password}
                                   disabledStatus={disabledStatus} value={""}/>
                        <span className="LoginScreen-textBelowInputs" onClick={() => {
                            setDisabledStatus(true);
                            setIsForgotPasswordModalOpen(true);
                        }}>
                            Forgot your password?
                        </span>
                        <div className="LoginScreen-termsAndConditions">
                            <button type="submit" className="LoginScreen-registerButton"
                                    id="login-button"
                                    disabled={disabledStatus ? "disabled" : null}>
                                Log in
                            </button>
                        </div>
                    </form>

                    <p className="LoginScreen-askRegister">Don't have an account?&nbsp;
                        <span className="LoginScreen-askRegisterButton">
                            <Link to="/register">Register</Link>
                        </span>
                    </p>

                </div>
                <div className="LoginScreen-phonesPictureContainer email">
                    <picture>
                        <source className="LoginScreen-phonesPictureContainer__image"
                                srcSet={phonesPictureWebP}
                                type="image/webp"/>
                        <img className="LoginScreen-phonesPictureContainer__image"
                             src={phonesPicture} alt=""/>
                    </picture>
                </div>
            </div>
            {isForgotPasswordModalOpen && <ForgotPasswordModal closeModalClicked={closeModalClicked}/>}
            {isVerifyEmailModalOpen && <VerifyEmailModal closeModalClicked={closeModalClicked} email={user.email} />}
        </div>
    </>
}


export default LoginScreen;
