import React from 'react';
import Header from "../../../../components/header/Header";
import Instruction from '../../../../components/instruction/Instruction';
import SideNavbar from '../../../../components/side-navbar/SideNavbar';
import "./howtotest.scss";
import {Link} from "react-router-dom";

class HowToTestScreen extends React.Component {

    componentDidMount() {
        document.title = "Beta Program - How to Test";
    }

    render() {
        return <>
            <div className="HowToTestScreen-container">
                <div className="HowToTestScreen-mainContent">
                    <p className="HowToTestScreen-title">How to test our beta</p>
                    <p className="HowToTestScreen-info">Testing our beta app could not be more easier. Follow these steps to
                        make sure our beta app works on your device.</p>
                    <div className="HowToTestScreen-instructions">
                        {
                            navigator.userAgent.match(/Android/i) ?
                                [androidInstructions(), iOSInstructions()]
                                : [iOSInstructions(), androidInstructions()]
                        }
                    </div>
                </div>
            </div>
        </>
    }
}

function iOSInstructions() {
    return <div key={1} className="HowToTestScreen-instructions-container">
        <div className="HowToTestScreen-instructions-header">
            <p>For iOS users</p>
        </div>
        <Instruction number={1}
                     text={<p>Add your iOS device by filling <Link
                         to="/account/beta-program/device-profile"><span
                         className="Instruction-info-red">the device profile form</span></Link>.
                     </p>}/>
        <Instruction number={2}
                     text={<p>You will receive an email from TestFlight with an invitation link to
                         download the app on your device. <br/><br/>
                         The email can take up to 48 hours before it arrives in your inbox.</p>}/>
        <Instruction number={3}
                     text={<p>Redeem the invitation link using the device with the same Apple ID as the
                         email you used to register your Freedrum account. <br/><br/>
                         You might need to download the <a
                             href="https://apps.apple.com/us/app/testflight/id899247664"><span
                             className="Instruction-info-red">TestFlight app</span></a> if
                         you don’t have it installed on your device.</p>}/>
        <Instruction number={4}
                     text={<p>Start TestFlight and our Freedrum 2 beta app will be on your list of apps
                         to test. Tap "<span
                             className="Instruction-info-semiBold">Install</span>" to add it to your
                         device. </p>}/>
        <Instruction number={5}
                     text={<p>The Freedrum 2 beta app is now successfully installed on your device. Give
                         it a try and don’t forget to <Link
                             to="/account/beta-program/feedback"><span className="Instruction-info-red">leave your feedback!</span></Link>
                     </p>}/>
        <Instruction number={6}
                     text={<p>If you test our app on a few different phone models, please update the
                         device list using the <Link
                             to="/account/beta-program/device-profile"><span
                             className="Instruction-info-red">device profile form</span></Link>. This
                         will make it easier to write feedback to our team..</p>}/>
    </div>
}

function androidInstructions() {
    return <div key={2} className="HowToTestScreen-instructions-container">
        <div className="HowToTestScreen-instructions-header">
            <p>For Android users</p>
        </div>
        <Instruction number={1}
                     text={<p>Add your Android device by filling <Link
                         to="/account/beta-program/device-profile"><span
                         className="Instruction-info-red">the device profile form</span></Link>.
                     </p>}/>
        <Instruction number={2}
                     text={<p>You will receive an email with an invitation link to download the app on
                         your device.<br/><br/>
                         The email can take up to 48 hours before it arrives in your inbox.</p>}/>
        <Instruction number={3}
                     text={<p>Click on the invitation link using the device with the same Google Account
                         as the email you used to register your Freedrum account. </p>}/>
        <Instruction number={4} text={<p>Accept the invitation by clicking the "<span
            className="Instruction-info-semiBold">Accept Invite</span>" button on bottom right and
            follow the instructions given by Google Play. </p>}/>
        <Instruction number={5}
                     text={<p>The Freedrum 2 beta app is now successfully installed on your device. Give
                         it a try and don’t forget to <Link
                             to="/account/beta-program/feedback"><span className="Instruction-info-red">leave your feedback!</span></Link>
                     </p>}/>
        <Instruction number={6}
                     text={<p>If you test our app on a few different phone models, please update the
                         device list using the <Link
                             to="/account/beta-program/device-profile"><span className="Instruction-info-red">device profile form</span></Link>.
                         This
                         will make it easier to write feedback to our team.</p>}/>
    </div>
}

export default HowToTestScreen;
