import React, {useState, useRef, useEffect} from 'react';
import {MdClose} from 'react-icons/md';
import './forgotpasswordmodal.scss';
import FormInput from "../forminput/FormInput";
import {passwordValidation, isValid} from "../forminput/validation";
import FocusTrap from "focus-trap-react";
import {doRequest} from '../../api/requests';
import {handleError} from "../../api/errorHandling";

const ResetPasswordModal = ({closeModalClicked, setModalStep, vid}) => {
    const focusRef = useRef(null);
    const [hasFocussedField, setHasFocussedField] = useState({password: false, repeatPassword: false});
    const [validation, setValidation] = useState({password: false, repeatPassword: false});
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [errorCode, setErrorCode] = useState(null);
    let renderError;

    useEffect(() => {
        let currentElement = focusRef.current[0];
        if (currentElement !== undefined) {
            currentElement.focus();
        }
    }, [focusRef]);

    if (errorCode !== null) {
        renderError = handleError(errorCode, null)
    }

    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setHasFocussedField({...hasFocussedField, [inputName]: true});
        setErrorCode(null);

        if (inputName === 'password') {
            setPassword(input.value);
            if (passwordValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
        }
        if (inputName === 'repeatPassword') {
            setRepeatPassword(input.value);
            if (passwordValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
        }
    }

    const savePasswordHandler = (e) => {
        e.preventDefault();

        setHasFocussedField({...hasFocussedField, password: true, repeatPassword: true});

        if (password !== repeatPassword) {
            setErrorCode("passwords-do-not-match")
            return;
        }

        if (!isValid(validation)) {
            setErrorCode("form-missing-fields")
            return;
        }

        setErrorCode(null);

        doRequest("POST", "/security/reset-password-step2", {vid: vid, password: password})
            .then(() => {
                setModalStep(3);
            })
            .catch(error => setErrorCode(error));
    }

    return <div className="modal-container">
        <FocusTrap>
            <div className="ReactModal__Content" role="dialog" aria-modal="true">
                <div className="VerifyEmailModal-container">
                    <div className="VerifyEmailModal-container-closeButton">
                        <button className="VerifyEmailModal-container-closeButton-image" onClick={closeModalClicked}>
                            <MdClose className="VerifyEmailModal-container-closeButton-image-icon"/>
                        </button>
                    </div>
                    <h1 className="VerifyEmailModal-title">Reset Password</h1>
                    <p className="pageStep-counter">2 / 3</p>
                    <p className="VerifyEmailModal-body" style={{marginBottom: "8px"}}>Please use a secure password
                        that is: </p>
                    <ul className="VerifyEmailModal-body-list">
                        <li className="VerifyEmailModal-body-list-items">
                            <span className="VerifyEmailModal-body-list-bullets"/>
                            <p>Longer than 8 characters.</p>
                        </li>
                        <li className="VerifyEmailModal-body-list-items">
                            <span className="VerifyEmailModal-body-list-bullets"/>
                            <p>Doesn’t contain your username.</p>
                        </li>
                    </ul>
                    {renderError}
                    <form ref={focusRef} tabIndex="-1" className="VerifyEmailModal-form" autoComplete="off"
                          onSubmit={savePasswordHandler}>
                        <FormInput handleFormChange={handleFormChange} name='password' title="New Password"
                                   placeholder="New Password" type="password" backgroundColor={"#F8F8F8"}
                                   valid={validation.password || !hasFocussedField.password} value={""}/>
                        <FormInput handleFormChange={handleFormChange} name='repeatPassword'
                                   title="Repeat New Password" placeholder="Repeat New Password" type="password"
                                   backgroundColor={"#F8F8F8"} value={""}
                                   valid={validation.repeatPassword || !hasFocussedField.repeatPassword} />
                        <button type="submit" className="VerifyEmailModal-verifyButton">
                            Save new password
                        </button>
                    </form>

                </div>
            </div>
        </FocusTrap>
    </div>
};


export default ResetPasswordModal;
