import './header.scss'
import React, {useState, useEffect} from 'react';
import {GiHamburgerMenu} from "react-icons/gi";
import {Redirect, Link} from "react-router-dom";
import FreedrumLogo from '../../assets/images/freedrum-logo.svg'
import {logOut, userRequests} from "../../api/userRequests";

function mobileNavHandler() {
    let element = document.getElementById("SideNavbar-content");
    if (element.className === 'SideNavbar-content-visible') {
        element.className = '';
    } else {
        element.className = 'SideNavbar-content-visible';
    }
}

class Header extends React.Component {

    render() {
        const {user, logout, history} = this.props;

        if (
            history.location.pathname === "/challenge" ||
            history.location.pathname === "/challenge/" ||
            history.location.pathname === "/challenge/login" ||
            history.location.pathname === "/challenge/login/" ||
            history.location.pathname === "/highscore/admin" ||
            history.location.pathname === "/highscore/admin/" ||
            history.location.pathname === "/highscore" ||
            history.location.pathname === "/highscore/"
            ) {
            return (<></>);
        }

        return (
            <div className="Header-container">
                <p className="Header-name">Hi, {user.name}</p>
                <span className="Header-burgerMenu">
                    <GiHamburgerMenu size={30} onClick={mobileNavHandler}/>
                </span>
                <span className="Header-freedrumLogo">
                    <Link to="">
                        <img src={FreedrumLogo} alt="Freedrum logo"/>
                    </Link>
                </span>
                <button className="Header-logout" onClick={logout} >
                    Log out
                </button>
            </div>
        );
    }
}

export default Header;