import "./registerscreenfromshopify.scss";
import {Link} from "react-router-dom";
import {useState, useEffect} from "react";
import {AiOutlineInfoCircle, AiOutlineCloseCircle, AiOutlineCheckCircle,} from "react-icons/ai";
import {Redirect} from "react-router";
import ReactGA from "react-ga4";

import {doRequest} from "../../../api/requests";
import {handleError} from "../../../api/errorHandling";
import FormInput from "../../../components/forminput/FormInput";
import {emailValidation, nameValidation, passwordValidation, promoCodeValidation, isValid} from "../../../components/forminput/validation";
import {ReactComponent as FreedrumStudioLogo} from "../../../assets/images/Logo_FD_Studio.svg";
import {ReactComponent as AmazonLogo} from "../../../assets/images/AmazonLogo.svg";

import VerifyEmailModal from "./VerifyEmailModal";

const RegisterScreenAmazon = () => {

    const [user, setUser] = useState({name: '', email: '', password: '', promocode: ''});
    const [validation, setValidation] = useState({name: false, email: false, password: false, promocode: false, conditions: false})
    const [hasFocussedField, setHasFocussedField] = useState({
        name: false,
        email: false,
        password: false,
        conditions: false,
        promocode: false,
    });
    const [promotionActive, setPromotionActive] = useState(false)

    const [modalIsOpen, setIsOpen] = useState(false);

    const [errorCode, setErrorCode] = useState(null);
    const [redirect, setRedirect] = useState(false);

    let renderError;

    useEffect(()=> {
        document.title = "Freedrum Studio - Create Account";
    });

    // Function to send data to backend and activate modal when response is success.
    const registerHandler = (e) => {
        e.preventDefault();

        setHasFocussedField({...hasFocussedField, email: true, password: true});

        if (!isValid(validation)) {
            if (!validation.email) {
                setErrorCode("email-address-invalid");
            } else if (!validation.password) {
                setErrorCode("password-short");
            } else if (!validation.conditions) {
                setErrorCode("accept-terms-missing");
            } else if (!validation.promocode) {
                setErrorCode("empty-promocode");
            }else {
                setErrorCode("form-missing-fields");
            }
            return;
        }

        setErrorCode(null);
    

        doRequest("POST", "/security/register/amazon", {email: user.email, password: user.password, name: user.name, promoCode: user.promocode})
            .then(result => {
                ReactGA.event({
                    category: 'Account',
                    action: 'Created account',
                });
                if (!result.data.verified) {
                    user.email = result.data.email;
                    setIsOpen(true);
                }
            })
            .catch(error => setErrorCode(error));
    };

    if (errorCode) {
        renderError = handleError(errorCode, null);
    }


    if (redirect) {
        return window.location.replace("https://freedrum.studio/thank-you-amazon.html");
    }

    const handleTermsOfService = (input) => {
        setValidation({...validation, [input.target.name]: !validation.conditions});
    }

    // User input handling - Show error when they input wrong email format, password less than 8 char, or empty name. CTA button also being disable.
    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setErrorCode(null);
        setHasFocussedField({...hasFocussedField, [inputName]: true});

        if (inputName === 'name') {
            setUser({...user, [inputName]: inputValue});
            if (nameValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        if (inputName === 'email') {
            setUser({...user, [inputName]: inputValue});
            if (emailValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        if (inputName === 'password') {
            setUser({...user, [inputName]: inputValue});
            if (passwordValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        if (inputName === 'promocode') {
            var promoCode = inputValue.trim();
            setUser({...user, [inputName]: promoCode});
            if (promoCodeValidation(promoCode)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }
    };

    return <>
       <div className="register__contentBox">
           <div className="register__contentBox-header">
                <div className="register__containerBox-freedrumLogoContainer">
                    <FreedrumStudioLogo className="freedrumStudioLogo"/>
                    <AmazonLogo className="amazonLogo"/>
                </div>
           </div>
           
            <div className="register__containerBox">
               
                <h1 className="register__containerBox-title">Create Account</h1>
                <p className="register__containerBox-text">Redeem your exclusive trial by creating a Freedrum Studio account.</p>
                {renderError}
                <div className="register__containerBox_formBox">
                    <form className="RegisterScreen-form" autoComplete="off" onSubmit={registerHandler}>
                        <FormInput name='name' handleFormChange={handleFormChange} title="Full name"
                                    placeholder="Your full name" type="text"
                                    valid={validation.name || !hasFocussedField.name} value={""} backgroundColor={"#f8f8f8"}/>
                        <FormInput name='email' handleFormChange={handleFormChange} title="Email"
                                    placeholder={"youremail@domain.com"} type="email"
                                    valid={validation.email || !hasFocussedField.email}
                                    value={""} backgroundColor={"#f8f8f8"}/>
                        <div className="RegisterScreen-textBelowInputs-container">
                            <AiOutlineInfoCircle className="RegisterScreen-infoIcon"/>
                            <p className="RegisterScreen-textBelowInputs">Please use a valid email address.</p>
                        </div>
                        <FormInput name='password' handleFormChange={handleFormChange} title="Password"
                                    placeholder="Password" type="password"
                                    valid={validation.password || !hasFocussedField.password} value={""} backgroundColor={"#f8f8f8"}/>
                        <p className="RegisterScreen-textBelowInputs password">{validation.password ?
                            <AiOutlineCheckCircle className="RegisterScreen-wrongPasswordIcon" color="green"/> :
                            <AiOutlineInfoCircle className="RegisterScreen-wrongPasswordIcon" color="#696969"/>
                        }
                            Minimum 8 characters.
                        </p>
                        <FormInput name='promocode' handleFormChange={handleFormChange} title="Promo Code"
                                    placeholder="Enter 6 or 8 digit number" type="text"
                                    valid={validation.promocode || !hasFocussedField.promocode} value={""} backgroundColor={"#f8f8f8"}/>
                        <div className="RegisterScreen-textBelowInputs-container">
                            <AiOutlineInfoCircle className="RegisterScreen-infoIcon"/>
                            <p className="RegisterScreen-textBelowInputs">Please enter the promo code sent to your email.</p>
                        </div>            
                        <div className="RegisterScreen-termsAndConditions">
                            <label className="RegisterScreen-termsAndConditions__label">
                                <input type="checkbox"
                                        name="conditions"
                                        checked={validation.conditions}
                                        onChange={handleTermsOfService}
                                        id="terms_checkbox"
                                />
                                <div className="checkbox-custom"/>
                                <p htmlFor="terms_checkbox">
                                    By signing up, you're agreeing to our
                                    <span className="RegisterScreen-termsAndConditions__textLink">
                                        <Link to={{pathname: "https://freedrum.rocks/pages/terms-and-conditions"}}
                                                target="_blank"> Terms of Use</Link></span> and
                                    <span className="RegisterScreen-termsAndConditions__textLink"><Link
                                        to={{pathname: "https://freedrum.rocks/pages/privacy-notice"}} target="_blank"> Privacy Policy</Link></span>.
                                </p>

                            </label>
                        </div>
                        <button type="submit" className="RegisterScreen-registerButton" id="register-button">
                            Create Account
                        </button>
                    </form>
                </div> 
                <p className="register__containerBox-information">
                </p>

                <p className="RegisterScreen-askLogin">Have an account?
                        <span className="RegisterScreen-askLoginButton"
                        > <Link to="/amazon/login"> Login</Link></span>
                </p>

            </div>
            {modalIsOpen && <VerifyEmailModal email={user.email} callback={() => setRedirect(true)} />}
       </div>
    </>
}

export default RegisterScreenAmazon;