import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './forgotpasswordmodal.scss';
import ResetPasswordModal from "./ResetPasswordModal";
import VerifyPasswordModal from "./VerifyResetPasswordModal";
import ConfirmationResetPasswordModal from "./ConfirmationResetPasswordModal";
import ForgotPasswordEmailModal from "./ForgotPasswordEmailModal";

const ForgotPasswordModal = ({closeModalClicked}) => {
    const [modalStep, setModalStep] = useState(1);
    const [vid, setVid] = useState("");

    let renderModal;
    if (modalStep === 1) {
        renderModal = <ForgotPasswordEmailModal
            setModalStep={setModalStep}
            closeModalClicked={closeModalClicked}
            setVid={setVid}/>
    } else if (modalStep === 2) {
        renderModal = <ResetPasswordModal
            setModalStep={setModalStep}
            closeModalClicked={closeModalClicked}
            vid={vid}/>
    } else if (modalStep === 3) {
        renderModal = <VerifyPasswordModal
            setModalStep={setModalStep}
            vid={vid}/>
    } else if (modalStep === 4) {
        renderModal = <ConfirmationResetPasswordModal
            closeModalClicked={closeModalClicked}/>
    }

    return ReactDOM.createPortal(
        <div className="modal-container">
            {renderModal}
        </div>, document.body
    );
};


export default ForgotPasswordModal;
