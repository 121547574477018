import "./feedbackitem.scss";

function FeedbackItem({lastItem, feedback, deleteFeedback}) {

    return (
        <div className="Feedback-container" style={lastItem ? {marginBottom: "16px"} : {}}>
            <div className="Feedback-header">
                <div>
                    <p className="Feedback-title">{feedback.title}</p>
                    <p className="Feedback-submitted">Submitted {
                        new Date(Date.parse(feedback['timeAdded'])).toLocaleString([], {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                        })
                    }</p>
                </div>
                <div>
                    <p className="Feedback-phoneOS">{feedback.device.model.platform}</p>
                    <p className="Feedback-phoneModel">{feedback.device.model.model}</p>
                </div>
            </div>

            <p className="Feedback-body">{feedback.body}</p>

            <p onClick={deleteFeedback} className="Feedback-delete">
                Delete
            </p>
        </div>
    );
}

export default FeedbackItem;
