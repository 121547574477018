import "./highscoreinput.scss"

import ReactDOM from "react-dom";
import React, {useState, useRef, useEffect} from "react";


function ResetConfirmationModal({resetDay, resetScoreboard, setResetConfirmationModalStatus }) {
 
    const closeModal = () => {
        setResetConfirmationModalStatus(false)
    }

    return ReactDOM.createPortal(
        <div className="confirmation-modal-container">
            <div className="confirmation-modal-container_Content">
                <h1 className="confirmation-modal-container_Content-title reset">Reset Score Entries</h1>
                <p className="confirmation-modal-container_Content-text reset">Reset all score entries?</p>
                <p className="confirmation-modal-container_Content-text">This action cannot be undone.</p>
                <div className="confirmation-modal-container_Content-ButtonBlock reset">
                    <button className="confirmation-modal-container_Content-ButtonBlock_Cancel" onClick={closeModal}>Cancel</button>
                    <button className="confirmation-modal-container_Content-ButtonBlock_Submit" onClick={resetScoreboard}>Reset</button>
                </div>
               
            </div>
        </div>, document.body
    );
}

export default ResetConfirmationModal;