import "./highscoreinput.scss"

import ReactDOM from "react-dom";
import React, {useState, useRef, useEffect} from "react";


function SubmitConfirmationModal({highscore, SongDay, saveHighScore, setSubmitConfirmationModalStatus }) {
 
    const closeModal = () => {
        setSubmitConfirmationModalStatus(false)
    }

    return ReactDOM.createPortal(
        <div className="confirmation-modal-container">
            <div className="confirmation-modal-container_Content">
                <h1 className="confirmation-modal-container_Content-title">Confirm Entry</h1>
                <p className="confirmation-modal-container_Content-text">Make sure the data entries are correct before submitting.</p>
                <div className="confirmation-modal-container_Content-entryBlock">
                    <p className="confirmation-modal-container_Content-entryBlock_title">Challenger's Name:</p>
                    <p className="confirmation-modal-container_Content-entryBlock_value">{highscore.name}</p>
                    <p className="confirmation-modal-container_Content-entryBlock_title">Social Media Link:</p>
                    <p className="confirmation-modal-container_Content-entryBlock_value" >{highscore.url}</p>
                    <p className="confirmation-modal-container_Content-entryBlock_title">Perfect Hits:</p>
                    <p className="confirmation-modal-container_Content-entryBlock_value">{highscore.score}</p>
                </div>
                <div className="confirmation-modal-container_Content-ButtonBlock">
                <button className="confirmation-modal-container_Content-ButtonBlock_Cancel" onClick={closeModal}>Cancel</button>
                <button className="confirmation-modal-container_Content-ButtonBlock_Submit" onClick={saveHighScore}>Submit</button>
                </div>
               
            </div>
        </div>, document.body
    );
}

export default SubmitConfirmationModal;