import './managesubscription.scss';
import React from 'react';
import ReactDOM from 'react-dom';

import FocusTrap from "focus-trap-react";

const ManageSubscriptionModal = ({modalStatusChange}) => {

    let renderModal = <FocusTrap>
        <div className="ReactModal__Content" role="dialog" aria-modal="true">
            <div className="ManageSubscriptionModal-container">
                <h1 className="ManageSubscriptionModal-title">Manage Subscription</h1>
                <p className="ManageSubscriptionModal-body">
                    Your subscription can be managed through Freedrum Studio on your device. Access it by navigating to the "More" menu in the app.
                </p> 
                <button type="button" className="RemoveFeedbackModal-CloseBtn" onClick={() => modalStatusChange(false)}>
                    OK
                </button>
            </div>
        </div>
    </FocusTrap>

    return ReactDOM.createPortal(
        <FocusTrap>
            <div className="modal-container">
                {renderModal}
            </div>
        </FocusTrap>, document.body
    );

}

export default ManageSubscriptionModal;