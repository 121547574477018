import React, {useRef, useEffect} from 'react';
import './forgotpasswordmodal.scss';

import FocusTrap from "focus-trap-react";

const ConfirmationResetPasswordModal = ({closeModalClicked}) => {
    const focusRef = useRef(null);

    useEffect(() => {
        let currentElement = focusRef.current[0];
        if (currentElement !== undefined) {
            currentElement.focus();
        }
    }, [focusRef]);

    return <div className="modal-container">
        <FocusTrap>
            <div className="ReactModal__Content" role="dialog" aria-modal="true">
                <div ref={focusRef} tabIndex="-1" className="VerifyEmailModal-container">
                    <h1 className="VerifyEmailModal-title">Password Saved</h1>
                    <p className="VerifyEmailModal-body" style={{marginBottom: "8px", textAlign: "center"}}>
                        Your new password has been saved.
                    </p>
                    <button onClick={closeModalClicked} type="button" className="VerifyEmailModal-resendCodeButton">
                        Back to log in
                    </button>
                </div>
            </div>
        </FocusTrap>
    </div>
};

export default ConfirmationResetPasswordModal;
