import ErrorBox from "../components/errorbox/ErrorBox";
import React from "react";
import {Link} from "react-router-dom";

export function handleError(error, callback) {
    return <ErrorBox text={errorText(error, callback)}/>
}

function errorText(error, callback) {
    switch (error) {
        case "form-missing-fields":
            return <p>Please fill in all fields.</p>
        case "email-missing":
            return <p>Can't find an account with this email address.</p>
        case "password-invalid":
            return <p>Incorrect password. Please try again.</p>
        case "email-invalid":
            return <p>Email address needs to be in the right format.</p>
        case "same-email":
            return <p>This email address is the same as your current email address.</p>
        case "email-not-verified":
            return <p>Email address has not been verified. Please <button onClick={callback}>click
                here</button> to continue.</p>
        case "wrong-password":
            return <p>Password does not match.</p>
        case "username-too-long":
            return <p>Username can't be longer than 20 characters.</p>
        case "name-too-long":
            return <p>Name can't be longer than 50 characters.</p>
        case "name-invalid":
            return <p>Name can't contain special characters.</p>
        case "username-invalid":
            return <p>Username can only contain alphanumeric characters and underscores.</p>
        case "id-invalid":
            return <p>Something went wrong. Please try again and, if the error is not fixed, contact our customer
                support.</p>
        case "rating-missing":
            return <p>Please give your feedback a rating.</p>
        case "password-short":
            return <p>Password needs to be 8 characters or more.</p>
        case "username-exists":
            return <p>That username is already taken.</p>
        case "code-invalid":
        case "invalid-code":
            return <p>Wrong verification code.</p>
        case "expired-code":
            return <p>Verification code has expired. Click Resend Code to receive a new code.</p>
        case "missing-device":
            return <p>Please select your operating system and device.</p>
        case "input-none":
            return <p className="onboarding-dialogBox-container-text-error">
                You must submit at least one operating system and one device.
            </p>
        case "email-exists":
            return <p>
                This email account already exists. Please try to <span><Link to="/login"> log in.</Link></span>
            </p>
        case "email-exists-challenge":
            return <p>
                This email account already exists. Please try to <span><Link to="/challenge/login"> log in.</Link></span>
            </p>
        case "accept-terms-missing":
            return <p>Please accept our Terms of Use and Privacy Policy.</p>
        case "passwords-do-not-match":
            return <p>Passwords don't match.</p>
        case "please-wait":
            return <p>Please wait a minute before clicking resend. Check your inbox and spam folder.</p>
        case "network-error":
            return <p>Can't connect to the server, check your internet connection or try again later.</p>
        case "apple-subscription-active":
            return <p>A subscription in the Apple store is already active.</p>
        case "google-subscription-active":
            return <p>A subscription in the Play store is already active.</p>
        case "issuer-invalid":
        case "audience-invalid":
        case "token-expired":
        case "code-hash-invalid":
        case "not-logged-in":
        case "invalid-credentials":
        case "invalid-device":
        case "invalid-device-model":
        case "invalid-feedback":
        case "invalid-app-version":
        case "empty-promocode":
            return <p>Please enter the 6 or 8 digit promo code.</p>
        case "promo-code-invalid":
            return <p>The promo code you entered is invalid.</p>
        case "promo-code-already-subscriber":
            return <p>You are already a subscriber to Freedrum Studio.</p>
        case "already-used-promo-code":
            return <p>You already used a promo code.</p>
        default:
            return <p>Something went wrong, please try again later.</p>
    }
}