import "../../pages/auth/registerscreen/registerscreenwithinfo.scss";
import {Link} from "react-router-dom";
import React from "react";


const RegisterFooterData = () => {
    return ( <div className="RegisterScreenUpdate-FooterContainer">
    <div className="RegisterScreenUpdate-FooterContainer__ContentBox">
        <div className="RegisterScreenUpdate-FooterContainer__ContentBox--Text">
            <div className="RegisterScreenUpdate-FooterContainer__ContentBox__Text__Links">
            <h3 className="RegisterScreenUpdate-FooterContainer__ContentBox__Text__Links__textLink">
                    <Link to={{pathname: 'https://contact.gorgias.help/forms/vmv0yfw2'}}
                        target="_blank">Contact</Link>
                </h3>
                <h3 className="RegisterScreenUpdate-FooterContainer__ContentBox__Text__Links__textLink">
                    <Link to={{pathname: "https://freedrum.rocks/pages/terms-and-conditions"}}
                        target="_blank"> Terms & Conditions</Link>
                </h3>
                <h3 className="RegisterScreenUpdate-FooterContainer__ContentBox__Text__Links__textLink">
                    <Link to={{pathname: "https://freedrum.rocks/pages/privacy-notice"}}
                        target="_blank"> Privacy Notice</Link>
                </h3>
            </div>
            <p className="RegisterScreenUpdate-FooterContainer__ContentBox--BodyText">© {(new Date().getFullYear())} Freedrum | Media Evolution, Stora Varvsgatan 6A, 211 19 Malmö, Sweden </p>
        </div>
    </div>  
</div>
 );
};

const RegisterFooter = React.memo(RegisterFooterData)
export default RegisterFooter;