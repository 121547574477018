import React, {useState, useRef, useEffect} from 'react';
import './forgotpasswordmodal.scss';
import PinField from 'react-pin-field';
import FocusTrap from "focus-trap-react";
import {doRequest} from '../../api/requests';
import {handleError} from "../../api/errorHandling";
import SuccessBox from "../successbox/SuccessBox";

const VerifyPasswordModal = ({setModalStep, vid}) => {
    const focusRef = useRef(null);
    const [errorCode, setErrorCode] = useState(null);
    const [secretCode, setSecretCode] = useState("")
    const [successStatus, setSuccessStatus] = useState(false);
    let renderSuccess;
    let renderError;

    useEffect(() => {
        let currentElement = focusRef.current[0];
        if (currentElement !== undefined) {
            currentElement.focus();
        }
    }, [focusRef]);

    const verificationHandler = (e) => {
        e.preventDefault();

        if (secretCode.length !== 6) {
            setErrorCode("form-missing-fields");
            return;
        }

        doRequest("POST", "/security/reset-password-step3", {vid: vid, code: secretCode})
            .then(() => {
                setModalStep(4);
            })
            .catch(error => setErrorCode(error));
    }

    if (errorCode !== null) {
        renderError = handleError(errorCode, null);
    }

    if (successStatus) {
        renderSuccess = <SuccessBox text={<p>Code has been sent again.</p>} setSuccessStatus={setSuccessStatus}/>
    }

    const resendCode = (vid) => {
        doRequest("POST", "/security/resend-verification", {vid: vid})
            .then(() => {
                setErrorCode(null);
                setSuccessStatus(true);
            })
            .catch((error) => {
                setErrorCode(error);
                setSuccessStatus(false);
            });
    }

    const secretCodeHandler = (code) => {
        setSecretCode(code);
        setErrorCode(null);
    };

    return <div className="modal-container">
        <FocusTrap>
            <div className="ReactModal__Content" role="dialog" aria-modal="true">
                <div className="VerifyEmailModal-container">
                    <h1 className="VerifyEmailModal-title">Verify email</h1>
                    <p className="pageStep-counter">3 / 3</p>
                    <p className="VerifyEmailModal-body" style={{marginBottom: "8px"}}>Enter the 6-digit verification
                        code sent to the email you submitted. </p>
                    <p className="VerifyEmailModal-body" style={{marginBottom: "0px"}}>Did not received the email?
                        Please check your spam folder or resend verification code.</p>
                    {renderError}
                    {renderSuccess}
                    <div tabIndex="-1" className="VerifyEmailModal-code-container">
                        <PinField ref={focusRef} length={6} className="VerifyEmailModal-code"
                                  onChange={secretCodeHandler}>
                        </PinField>
                    </div>
                    <button onClick={verificationHandler} type="button" className="VerifyEmailModal-verifyButton">
                        Verify
                    </button>
                    <button type="button" className="VerifyEmailModal-resendCodeButton"
                            onClick={() => resendCode(vid)}>Resend code
                    </button>
                </div>
            </div>
        </FocusTrap>
    </div>
};


export default VerifyPasswordModal;
