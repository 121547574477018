import "../registerscreen/registerscreenchallenge.scss";

import {useState, useEffect, useRef, useCallback} from "react";
import FormInput                                        from "../../../components/forminput/FormInput";
import ForgotPasswordModal                              from "../../../components/forgot-password/ForgotPasswordModal";
import {emailValidation, passwordValidation, isValid}   from "../../../components/forminput/validation";
import {doRequest}                                      from "../../../api/requests";
import {saveCredentials}                                from "../../../api/security";
import {handleError}                                    from "../../../api/errorHandling";
import {ReactComponent as FreedrumLogo}                 from "../../../assets/images/freedrum-logo.svg";
import {ReactComponent as NammLogo}                     from "../../../assets/images/minc_logo.svg";
import {Link} from "react-router-dom";

import VerifyEmailModal                                 from '../registerscreen/VerifyEmailModal';

const LoginChallengePage = ({callback}) => {

    const [user, setUser] = useState({email: '', password: ''});
    const [hasFocussedField, setHasFocussedField] = useState({email: false, password: false})
    const [validation, setValidation] = useState({email: false, password: false})
    const blurElement = useRef(null);

    const [errorCode, setErrorCode] = useState(null);
    const [redirect, setRedirect] = useState(false);

    const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
    const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
    const [disabledStatus, setDisabledStatus] = useState(false);

    const [successLogin, setSuccesLogin] = useState(false);

    let renderError;

    useEffect(() => {
        document.title = "Freedrum - Account Login";
        if (!isForgotPasswordModalOpen && !isVerifyEmailModalOpen) {
            setDisabledStatus(false);
        }
    }, [isForgotPasswordModalOpen, isVerifyEmailModalOpen, setDisabledStatus])

    const loginHandler = (e) => {
        e.preventDefault();

        if (disabledStatus) { return; }

        setHasFocussedField({...hasFocussedField, email: true, password: true});

        if (!isValid(validation)) {
            if (!validation.email) {
                setErrorCode("email-invalid");
            } else if (!validation.password) {
                setErrorCode("password-short");
            } else {
                setErrorCode("form-missing-fields");
            }
            return;
        }

        setErrorCode(null);

        doRequest("POST", "/security/signin/event", {email: user.email, password: user.password})
            .then(result => {
                if (result !== undefined) {
                    saveCredentials(result.data, () => {
                        setRedirect(true)
                    });
                    callback();
                }
            })
            .catch(error => setErrorCode(error));
    }

    if (errorCode != null) {
        renderError = handleError(errorCode, () => {
            setDisabledStatus(true);
            setIsVerifyEmailModalOpen(true);
        })
    }

    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setErrorCode(null);
        setHasFocussedField({...hasFocussedField, [inputName]: true});

        if (inputName === 'email') {
            setUser({...user, [inputName]: inputValue});
            if (emailValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }
        if (inputName === 'password') {
            setUser({...user, [inputName]: inputValue});
            if (passwordValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        setUser({...user, [inputName]: inputValue});
    }

    const closeModalClicked = () => {
        setDisabledStatus(true)
        setIsForgotPasswordModalOpen(false);
        setIsVerifyEmailModalOpen(false);
    }

    return <>
    <div className="register__contentBox">

        <div className="RegisterScreenNamm-Container__InnerBox">
            <div className="RegisterScreenNamm-Container__InnerBox-TopArea__header">
                <div className="RegisterScreenNamm-Container__InnerBox-TopArea__header-freedrumLogoContainer">
                    <FreedrumLogo/>
                </div>
                <div className="RegisterScreenNamm-Container__InnerBox-TopArea__header-NammLogoContainer">
                    <NammLogo/>
                </div>
            </div>
            <hr style={{
                    color: '#F5F5F5',
                    backgroundColor: '#F5F5F5',
                    height: 0.5,
                    borderColor : '#F5F5F5',
                    marginBottom: "32px",
                    opacity:0.3,
                }}/>
            <div className="RegisterScreenNamm-Container__InnerBox-TopArea__FormContainer">
                <div className="RegisterScreenNamm-Container__InnerBox-TopArea__FormContainer-box">
                    <h2 className="RegisterScreenNamm-Container__InnerBox-TopArea__FormContainer-box-title">Join the Freedrum Challenge by log in to your Freedrum account.</h2>
                    <div className="Login__containerBox-formBox">
                        <form className="LoginScreen-form" autoComplete="off" onSubmit={loginHandler}>
                            {renderError}
                            <FormInput handleFormChange={handleFormChange} name='email' title="Email"
                                placeholder="youremail@domain.com" type="email"
                                valid={validation.email || !hasFocussedField.email}
                                disabledStatus={disabledStatus} value={""}
                                backgroundColor={"#f8f8f8"}/>
                            <FormInput handleFormChange={handleFormChange} name='password' title="Password"
                                placeholder="Password" type="password"
                                valid={validation.password || !hasFocussedField.password}
                                disabledStatus={disabledStatus} value={""}
                                backgroundColor={"#f8f8f8"}/>
                            <span className="LoginScreen-textBelowInputs" onClick={() => {
                                setDisabledStatus(true);
                                setIsForgotPasswordModalOpen(true);
                            }}>
                                Forgot your password?
                            </span>
                            <button type="submit" className="RegisterScreen-registerButton" id="login-button" disabled={disabledStatus ? "disabled" : null}>
                                    Log in
                            </button>
                        </form>
                    </div>
                </div>
            </div>
         </div>
         <div className="RegisterPage__logIn">
                    <p>Don't have an account?
                        <span className="RegisterPage__logIn-span">
                            <Link to="/challenge"> Register here.</Link>
                        </span>
                    </p>
        </div> 
         <p className="footer-text">© {new Date().getFullYear()} Freedrum | <a href= "mailto:hello@freedrum.rocks">hello@freedrum.rocks</a> </p>
        
        {isForgotPasswordModalOpen && <ForgotPasswordModal closeModalClicked={closeModalClicked}/>}
        {isVerifyEmailModalOpen && <VerifyEmailModal closeModalClicked={closeModalClicked} email={user.email} />}
    </div>
 </>
}

export default LoginChallengePage;