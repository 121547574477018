import FormInput from "../forminput/FormInput";
import React, {useEffect, useState} from "react";
import {isValid, nameValidation, usernameValidation} from "../forminput/validation";
import {doAuthenticatedRequest} from "../../api/requests";
import SuccessBox from "../successbox/SuccessBox";
import {handleError} from "../../api/errorHandling";

export function EditProfileComponent({currentUsername, setCurrentUsername, user, setUser}) {
    const [validation, setValidation] = useState({name: false, username: false})
    const [errorCode, setErrorCode] = useState(null);
    const [successStatus, setSuccessStatus] = useState(false);

    let renderError;
    let renderSuccess;

    useEffect(() => {
        setValidation({
            name: nameValidation(user.name),
            username: usernameValidation(user.username)
        });
    }, [user])

    const changeProfile = (e) => {
        e.preventDefault();

        if (!isValid(validation)) {
            if (user.name.length > 50) {
                setErrorCode("name-too-long");
            } else if (!validation.name) {
                setErrorCode("name-invalid");
            } else if (user.username.length > 20) {
                setErrorCode("username-too-long");
            } else if (!validation.username) {
                setErrorCode("username-invalid");
            } else {
                setErrorCode("form-missing-fields")
            }
            return;
        }

        setSuccessStatus(false)
        setErrorCode(null);

        doAuthenticatedRequest("PUT", "/users/self",
            user.username === currentUsername ?
                {"newName": user.name} :
                {"newName": user.name, "newUsername": user.username})
            .then((result) => {
                setUser({name: result.data.name, username: result.data.username});
                setCurrentUsername(result.data.username);
                setSuccessStatus(true);
            })
            .catch(error => setErrorCode(error));
    };

    const profileFormHandler = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setUser({...user, [inputName]: inputValue});
        setErrorCode(null);
        setSuccessStatus(false);
    };

    if (successStatus) {
        renderSuccess = <SuccessBox text={<p>Changes saved.</p>} setSuccessStatus={setSuccessStatus}/>
    }

    if (errorCode !== null) {
        renderError = handleError(errorCode, null);
    }

    return <><p className="EditProfileScreen-title" style={{marginBottom: "16px"}}>Edit Profile</p>
        {renderSuccess}
        {renderError}
        <form className="EditProfileScreen-form" onSubmit={changeProfile}>
            <FormInput title="Full Name"
                       name="name"
                       handleFormChange={profileFormHandler}
                       type='text'
                       valid={validation.name}
                       value={user.name}
                       placeholder={user.name} />
            <FormInput title="Username"
                       name="username"
                       handleFormChange={profileFormHandler}
                       type='text'
                       valid={validation.username}
                       value={user.username}
                       placeholder={user.username} />
            <button type="submit" className="EditProfileScreen-sendProfileButton">
                Save
            </button>
        </form>
    </>;
}