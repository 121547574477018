import './deviceprofilescreen.scss'
import Header from "../../../../components/header/Header";
import SideNavbar from "../../../../components/side-navbar/SideNavbar";
import addButton from "../../../../assets/images/add-button.svg";
import {Link} from "react-router-dom";
import {useState, useEffect} from "react";
import RemoveDeviceModal from "./RemoveDeviceModal";
import {requestAllDevices} from "../../../../api/deviceRequests";
import SuccessBox from "../../../../components/successbox/SuccessBox";

function DeviceProfileScreen() {
    const [devicesMap, setDevicesMap] = useState([[], []]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [deviceID, setDeviceID] = useState("");
    const [successStatus, setSuccessStatus] = useState(true);
    let renderSuccess;

    useEffect(() => {
        document.title = "Beta Program - Device Profile";
        requestAllDevices(true, setDevicesMap);
    }, []);

    function showRemoveModal(deviceId) {
        setDeviceID(deviceId);
        setIsOpen(true);
    }

    if (successStatus && new URLSearchParams(window.location.search).get('added')) {
        renderSuccess = <SuccessBox text={<p>Device added.</p>} setSuccessStatus={setSuccessStatus}/>
    }

    return <>
        <div className="DeviceProfileScreen-container">
            <div className='DeviceProfileScreen-mainContent'>
                <div className='DeviceProfileScreen-header'>
                    <p className="DeviceProfileScreen-title">Device Profile</p>
                    <p className="DeviceProfileScreen-description">This is the list of the devices that you have
                        tested.</p>
                </div>

                <div className="DeviceProfileScreen-addButton">
                    <Link to="/account/beta-program/device-profile/add"> <img src={addButton} alt=""/></Link>
                </div>
                {renderSuccess}
                {devicesMap.map((devices, index) => (
                    devices.length > 0 &&
                    <div key={index} className="DeviceProfileScreen-phoneContainer">
                        <div className="DeviceProfileScreen-OS-container">
                            <p className="DeviceProfileScreen-OS-text">{index === 0 ? "iOS iPhone" : "Android Phone"}</p>
                            <p className="DeviceProfileScreen-count">
                                {devices.length === 1 ? "1 device" : devices.length + " devices"}
                            </p>
                        </div>
                        <div className="DeviceProfileScreen-model-container">
                            {devices.map((device, index) => (
                                    <p key={index} className="DeviceProfileScreen-model-text" style={
                                        index === devices.length - 1 ?
                                            {borderRadius: "0 0 14px 14px"} :
                                            {marginBottom: "10px"}
                                    }>
                                        <span>{device.model.model}</span>
                                        <img className="DeviceProfileScreen-model-image"
                                             onClick={() => showRemoveModal(device.id)}
                                             src={require('../../../../assets/images/remove-button.svg').default}
                                             alt=""/>
                                    </p>
                                )
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {modalIsOpen && <RemoveDeviceModal IsOpen={setIsOpen} deviceID={deviceID} setDevicesMap={setDevicesMap} />}
        </div>
    </>
}

export default DeviceProfileScreen;
