import "./highscoreinput.scss"
import React, {useState, useEffect} from 'react';
import FormInput from "../forminput/FormInput";
import {doAuthenticatedRequest} from '../../api/requests';
import {MdOutlineClose,} from "react-icons/md";
import {ReactComponent as FreedrumStudioLogo} from "../../assets/images/Logo_FD_Studio.svg";
import SubmitConfirmationModal from "./SubmitConfirmationModal";
import ResetConfirmationModal from "./ResetConfirmationModal";

const HighscoreInput = () => {
    const [highScore, setHighScore] = useState({name: null, score: null, url: null, song: 5});
    const [songDay, setSongDay] = useState(5);
    const [resetConfirmationModalStatus, setResetConfirmationModalStatus] = useState(false);
    const [submitConfirmationModalStatus, setSubmitConfirmationModalStatus] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);
    const [resetDay, setResetDay] = useState(null);

    let successBox;

    useEffect( () => {
        document.title = "Freedrum Challenge - Admin Input";
    })


    const getChallengerData = (e) => {
        e.preventDefault();
        setSubmitConfirmationModalStatus(true);
    }

    const confirmReset = (e) => {
        e.preventDefault();
        setResetConfirmationModalStatus(true)
    }

    const handleResetDayChange =(event) => {
        setResetDay(event.target.value);
    }

    const handleChange =(event) => {
        setHighScore({...highScore, ["song"]: event.target.value});
    }

    const saveHighScore = (e) => {
        e.preventDefault();
        if (highScore.url != null && highScore.name != null && highScore.score != null && songDay != null) {
            doAuthenticatedRequest("POST", "/scoreboard", highScore)
                        .then(() => {
                            setSubmitConfirmationModalStatus(false);
                            setSuccessAlert(true)
                        })
                        .catch(error => {
                            setSubmitConfirmationModalStatus(false);
                            setErrorAlert("Challenger's Code Incorrect");
                        });
        }
    };

    const resetScoreboard = (e) => {
                e.preventDefault(); 
                doAuthenticatedRequest("POST", "/scoreboard/reset", {songId: 5})
                                        .then(() => {
                                            setResetConfirmationModalStatus(false);
                                        })
                                        .catch(error => {});
    }

    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setHighScore({...highScore, [inputName]: inputValue});
    }

    const closeAlert = () => {
        setSuccessAlert(false)
        setErrorAlert(null)
    }

    if (successAlert || errorAlert) {
        successBox = <div className={successAlert ? 'success-alert' : 'error-alert'}>
            {successAlert ? <p>Data entries have been saved successfully.</p> : <p>{errorAlert}</p>}
            <MdOutlineClose className="success-alert_icon" onClick={closeAlert}/>
        </div>;
    }

    return <>
            <div className="Challenge-Admin-Container">
                <div className="Challenge-Admin-Container__Box">
                    <div className="Challenge-Admin-Container__Box-TopArea__header">
                       <FreedrumStudioLogo className="freedrumStudioLogo"/>
                    </div>
                    <h1 className="Challenge-Admin-Container__Box-Title">Challenge Score Entry</h1> 
                    {successBox}
                    <form autoComplete="off" onSubmit={getChallengerData}>
                        <div className="Challenge-Admin-Container__Box-FormInput" >
                            <FormInput handleFormChange={handleFormChange} name='name' title="Challenger's Name"
                                                        placeholder="Max 10 characters" type="name" backgroundColor={"#F8F8F8"}
                                                        valid={true} value={""} />
                        </div>
                        <div className="Challenge-Admin-Container__Box-FormInput" >
                            <FormInput handleFormChange={handleFormChange} name='url' title="Social Media Link"
                                                        placeholder="URL" type="url" backgroundColor={"#F8F8F8"}
                                                        valid={true} value={""}/>
                        </div>
                        <div className="Challenge-Admin-Container__Box-FormInput" >
                            <FormInput handleFormChange={handleFormChange} name='score' title="Perfect Hits"
                                                            placeholder="Perfect Hits" type="number" backgroundColor={"#F8F8F8"}
                                                            valid={true} value={""}/>
                        </div>
                        <div className="Challenge-Admin-Container__Box-Selector">
                            <select name="day" id="challenge-days" defaultValue="5" hidden="hidden"/>
                        </div>

                        <button disabled={(highScore.name && highScore.url && highScore.score) ? '' : 'disabled'} className="Challenge-Admin-Container__Box-SubmitButton" type="submit" >
                            Save Entry
                        </button>
                     </form>
                </div>
                <div className="Challenge-Admin-Container__Box-Reset">
                    <form onSubmit={confirmReset} className="Challenge-Admin-Container__Box-Reset-Form">
                        
                        <div className="Challenge-Admin-Container__Box-Reset-Selector">
                                <label htmlFor="resetDay">Reset score entries</label>
                                <select name="resetDay" id="challenge-resetdays" defaultValue="5" hidden="hidden"/>

                        </div>
                        <button className="Challenge-Admin-Container__Box-Reset-Button" type="submit">Reset Entries</button>
                        
                    </form>
                </div>
                <p className="footer-text">© {new Date().getFullYear()} Freedrum Studio | <a href= "mailto:hello@freedrum.rocks">hello@freedrum.rocks</a> </p>
                {submitConfirmationModalStatus && <SubmitConfirmationModal highscore={highScore} SongDay={songDay} saveHighScore={saveHighScore} setSubmitConfirmationModalStatus={setSubmitConfirmationModalStatus} />}
                {resetConfirmationModalStatus && <ResetConfirmationModal resetDay={resetDay} resetScoreboard={resetScoreboard} setResetConfirmationModalStatus={setResetConfirmationModalStatus} />}
       </div>
       </>
};

export default HighscoreInput;