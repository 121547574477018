import {MdClose} from "react-icons/md";
import FormInput from "../forminput/FormInput";
import FocusTrap from "focus-trap-react";
import React, {useEffect, useRef, useState} from "react";
import {emailValidation} from "../forminput/validation";
import {doRequest} from "../../api/requests";
import {handleError} from "../../api/errorHandling";

const ForgotPasswordEmailModal = ({setModalStep, setVid, closeModalClicked}) => {
    const focusRef = useRef(null);
    const [usersEmail, setUsersEmail] = useState("");
    const [validation, setValidation] = useState({email: false});
    const [hasFocussedField, setHasFocussedField] = useState({email: false});
    const [errorCode, setErrorCode] = useState(null);
    let renderError;

    useEffect(() => {
        let currentElement = focusRef.current[0];
        if (currentElement !== undefined) {
            currentElement.focus();
        }
    }, [focusRef]);

    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setHasFocussedField({...hasFocussedField, [inputName]: true});
        setErrorCode(null);

        if (inputName === 'email') {
            setUsersEmail(input.value);
            if (emailValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
        }
    }

    const sendEmailHandler = (e) => {
        e.preventDefault();

        setHasFocussedField({...hasFocussedField, email: true});

        setErrorCode(null);

        doRequest("POST", "/security/reset-password-step1", {email: usersEmail})
            .then(result => {
                setVid(result.data.vid);
                setModalStep(2);
            })
            .catch(() => {
                setErrorCode('email-missing');
            });
    }

    if (errorCode !== null) {
        renderError = handleError(errorCode, null);
    }

    return <FocusTrap>
        <div role="dialog" aria-modal="true" className="ReactModal__Content">
            <div className="VerifyEmailModal-container">
                <div className="VerifyEmailModal-container-closeButton">
                    <button className="VerifyEmailModal-container-closeButton-image" onClick={closeModalClicked}>
                        <MdClose className="VerifyEmailModal-container-closeButton-image-icon"/>
                    </button>
                </div>
                <h1 className="VerifyEmailModal-title">Forgot Password</h1>
                <p className="pageStep-counter">1 / 3</p>
                <p className="VerifyEmailModal-body" style={{marginBottom: "0px"}}>Have you forgotten your
                    password? It happens. Enter the email associated with your account and we’ll send you a
                    6-digit verification code to reset your password in the next step. </p>
                {renderError}
                <form ref={focusRef} tabIndex="-1" className="VerifyEmailModal-form" autoComplete="off"
                      onSubmit={sendEmailHandler}>
                    <FormInput handleFormChange={handleFormChange} name='email' title="Email"
                               placeholder="youremail@domain.com" type="email"
                               valid={validation.email || !hasFocussedField.email}
                               backgroundColor={"#F8F8F8"} value={""}/>
                    <button type="submit" className="VerifyEmailModal-verifyButton">
                        Send code
                    </button>
                </form>

            </div>
        </div>
    </FocusTrap>
}

export default ForgotPasswordEmailModal;
